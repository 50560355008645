import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Button, Table, Modal } from 'react-bootstrap';
import { MdAddCircle } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { RiDeleteBin6Fill } from "react-icons/ri";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { FaArrowLeftLong, FaPlus } from "react-icons/fa6";

import Addproduct from '../commanpage/Addproduct';
import { FaEye } from "react-icons/fa";

import AddproductRaw from '../commanpage/AddrowmaterialOrder';

import { RiDeleteBin6Line } from "react-icons/ri";
export default function Takeorder() {
    const [errors, setErrors] = useState({});
    const token = localStorage.getItem('token');
    const order_Update_id = localStorage.getItem('order_Update_id');
    const orderid = localStorage.getItem('up_orderid');
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [extra_categories, setCategoriesExtra] = useState([]);
    const [productdata, setproduct] = useState([]);
    const [viewdata, setViewdata] = useState([]);
    const [accountlist, setAccountList] = useState([]);
    const [EnterQty, setEnterQty] = useState();
    const [EnterProQty, setEnterProQty] = useState();
    const [TotalQtyPrice, setTotalQtyPrice] = useState();
    const [CalculatedDueAmt, setCalculatedDueAmt] = useState();
    // console.warn(accountlist);
    const [venderlist, setVenderlist] = useState([]);
    const [data, setViewData] = useState({});
    // console.warn(data)

    const [productorderdata, setproductorder] = useState([]);
    const [show, setShow] = useState(false);
    const [rows, setRows] = useState([{ category_id: null, product_id: null, vendor_id: null, qty: '', price: '', id: '', order_id: '' }]);
    const [rowother, setRowother] = useState([{ tr_charge: null, lable_charge: null, name: '', id: '' }]);
    const [modalState, setModalState] = useState({ addproduct: false, addraw: false });
    const handleCloseEditModal = () => setShowEditModal(false);
    const handleShowEditModal = () => setShowEditModal(true);
    const [showEditModal, setShowEditModal] = useState(false);
    const [searchNameList, setSearchNameList] = useState([]);
    const [filteredNameList, setFilteredNameList] = useState([]);
    const [selectedOrderType, setSelectedOrderType] = useState([0]);
    const [customers, setCustomers] = useState([]);
    const [Totalamountnew, setTotalamount] = useState();
    const [Dueamountold, setDueamount] = useState();
    const [TotalcustomerDataamount, setTotalcustomerDataamount] = useState();
    const [EnterQtyStockId, setEnterQtyStockId] = useState();
    const [EnterQtyStockName, setEnterQtyStockName] = useState();
    const [EnterQtyStockproqty, setEnterQtyStockpro_qty] = useState();

    const [vendors, setVendors] = useState([]);
    const [rows1, setRows1] = useState([{
        vendor_id: null,
        category_id: null,
        customer_id: null,
        name: '',
        pro_qty: '',
        price: '',
        total_amount: ''
    }]);

    const customerOptions = customers.map(item => ({ value: item.id, label: item.customer_name }));
    const vendorOptions1 = vendors.map(item => ({ value: item.id, label: item.vendor_name }));
    const handleAddRow1 = () => {
        setRows1([...rows1, { vendor_id: null, category_id: null, customer_id: null, name: '', pro_qty: '', price: '', total_amount: '' }]);
    };

    const handleDeleteRow1 = index => {
        setRows1(rows1.filter((_, i) => i !== index));
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const [categoriesRes, customersRes, vendorsRes] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_URL}category-list`, { headers: { 'x-access-token': token } }),
                    axios.get(`${process.env.REACT_APP_API_URL}Custumer-name-list`, { headers: { 'x-access-token': token } }),
                    axios.get(`${process.env.REACT_APP_API_URL}Vender-list`, { headers: { 'x-access-token': token } })
                ]);

                setCategories(categoriesRes.data.data || []);
                setCategoriesExtra(categoriesRes.data.data || []);
                setCustomers(customersRes.data.data || []);
                setVendors(vendorsRes.data.data || []);
            } catch (error) {
                toast.error('Failed to fetch data.');
            }
        };

        fetchData();
    }, [token]);



    const handleOrderTypeChange = (selectedOption) => {
        setSelectedOrderType(selectedOption);
    };
    const [currentEditId, setCurrentEditId] = useState(null);
    const [formData, setFormData] = useState({
        order_date: new Date().toISOString().split('T')[0], customer_type: "", customer_name: "", mobile_number: "", invoice_number_manual: '', vehicle_number: '', gst_in_no: '', c_gst: '', s_gst: '', description: '', email: "", site_name: "", address1: "", address2: "", city: "", state: "", pincode: "", manufacture: "Trading", amount: "", receiver_name: "", payer_name: "", delivery_date: "", due_amt: CalculatedDueAmt,
    });
    const handleAddRow = () => { setRows([...rows, { category_id: null, product_id: null, vendor_id: null, qty: '', price: '' }]); };
    const handleAddRowOther = () => { setRowother([...rowother, { tr_charge: null, lable_charge: null, name: '' }]); };
    const navigate = useNavigate();
    const categoryOptions = categories.map((item) => ({ value: item.id, label: item.name, }));
    const extra_categoryOptions = extra_categories.map((item) => ({ value: item.id, label: item.name, }));
    const vendorOptions = venderlist.map((item) => ({ value: item.id, label: item.vendor_name, }));

    const getProductOptionsForRow = (category_id, manufacture) => {
        return productorderdata.filter(item => item.category_id == category_id && item.manufacture == manufacture)
            .map(item => ({
                value: item.id,
                label: item.name,
            }));
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Validation for mobile number
        if (name === 'mobile_number') {
            // Allow only digits and limit the length to 10
            const formattedValue = value.replace(/\D/g, '').slice(0, 10);
            setFormData({ ...formData, [name]: formattedValue });

            // If the length is not 10, set an error
            if (formattedValue.length !== 10) {
                setErrors((prevErrors) => ({ ...prevErrors, [name]: 'Mobile number must be 10 digits long' }));
            } else {
                setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
            }
        } else {
            setFormData({ ...formData, [name]: value });
            setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
        }
        if (name === 'customer_name') {
            if (value.trim() !== '') {
                filterSearchName(value);

            } else {
                setSearchNameList([]);
            }
        }
    };


    const handleSelectChange = (name, selectedOption) => {

        if (name != 'account_id' || name === 'account_mode') {
            console.warn("name", selectedOption)

            setFormData({ ...formData, [name]: selectedOption ? selectedOption.value : null });
        } else if (name === 'product_id') {
            const selectedProduct = selectedOption ? { id: selectedOption.value, name: selectedOption.label } : null;
            setFormData({ ...formData, [name]: selectedProduct });
        } else {
            setFormData({ ...formData, [name]: selectedOption });
        }
    };

    const handleRowChange = (index, field, value) => {
        // alert('pppppp');
        const updatedRows = [...rows];
        updatedRows[index][field] = value;

        if (field === 'pro_qty' || field === 'price') {
            updatedRows[index].total_amount = (updatedRows[index].pro_qty * updatedRows[index].price).toFixed(2);
        }

        setRows(updatedRows);
        if (updatedRows.length > 0) {
            const { qty, pro_qty, price } = updatedRows[0];
            setEnterQty(qty);
            setEnterProQty(pro_qty);
            setTotalQtyPrice(qty * price)
            console.warn("Qty:", qty, "Pro Qty:", updatedRows, price, qty * price);
        }

        console.warn("dataaaa", updatedRows.map(item => item.qty));
    };
    const handleRowChangeOther = (index, field, value) => {
        const updatedRows = [...rowother];
        updatedRows[index][field] = value;
        setRowother(updatedRows);
    };
    const handleRowChangeExtra = (index, field, value) => {
        const updatedRows = [...rows1];
        updatedRows[index][field] = value;
        if (field === 'pro_qty' || field === 'price') {
            updatedRows[index].total_amount = (updatedRows[index].pro_qty * updatedRows[index].price).toFixed(2);
        }
        setRows1(updatedRows);
    };
    const validateForm = () => {
        const newErrors = {};

        // Check for required fields
        Object.keys(formData).forEach((key) => {
            if (!formData[key] && key !== 'email' && key !== 'site_name' && key !== 'pincode' && key !== 'address2' && key !== 'qty' && key !== 'customer_type' && key !== 'city' && key !== 'state' && key !== 'address1' && key !== 'description' && key !== 'invoice_number_manual' && key !== 'vehicle_number' && key !== 'gst_in_no' && key !== 'c_gst' && key !== 's_gst') {
                newErrors[key] = 'This field is required';
            }
        });

        // Validate quantity against available stock
        rows.forEach((row, index) => {
            const product = productorderdata.find(item => item.id === row.product_id?.id);
            if (product && parseInt(row.qty) > parseInt(product.pro_qty)) {
                newErrors[`qty_${index}`] = `Quantity exceeds available stock (${product.pro_qty})`;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleSubmit = async (url, formData, rows, successCallback) => {
        // const totalAmount = Number(formData.total_amount) || 0;
        const totalAmount = Number(TotalQtyPrice) || 0;
        const enteredAmount = Number(formData.amount) || 0;
        const previousDue = Number(Dueamountold) || 0;
        const currentDue = Number(formData.due_amt) || 0;
        const newDueAmount = totalAmount - enteredAmount;
        const remainingDue = newDueAmount - previousDue;

        if (TotalcustomerDataamount > enteredAmount) {
            Swal.fire({
                text: `Payment exceeds due amount. You are trying to pay ${enteredAmount}, but the due amount is ${previousDue}.`,
                icon: 'warning',
                showConfirmButton: false, // Disable confirm button
                showCancelButton: false, // Disable cancel button
                timer: 3000, // Automatically close the alert after 3 seconds
                timerProgressBar: true, // Show a progress bar for the timer
            });
            return; // Exit the function after showing the alert
        }

        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to submit the form?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, submit it!',
            cancelButtonText: 'No, cancel!',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
        });

        // If the user confirms, proceed with the submission
        if (result.isConfirmed) {
            setLoading(true);
            try {
                const { product_id, ...requestData } = formData;

                // handlePaymentSubmit();
                handleSubmitStockOrder();
                const payload = {
                    ...requestData,
                    // products: productsData,
                };

                const response = await axios.post(url, payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });

                if (response.data.status === 1) {


                    Swal.fire({
                        title: 'Success',
                        text: response.data.message,
                        icon: 'success',
                        timer: 3000,
                        showConfirmButton: false,
                    });
                    setFormData({
                        order_date: new Date().toISOString().split('T')[0], customer_type: "", customer_name: "", mobile_number: "", invoice_number_manual: '', vehicle_number: '', gst_in_no: '', c_gst: '', s_gst: '', description: '', email: "", site_name: "", address1: "", address2: "", city: "", state: "", pincode: "", amount: "", receiver_name: "", payer_name: "", delivery_date: "",
                    });
                    setRows([]);
                    setRowother([]);
                    setTimeout(() => {
                        successCallback && successCallback(response.data.payload);
                        navigate('/orderview')
                    }, 1000);
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: response.data.message,
                        icon: 'error',
                    });
                }
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: 'An error occurred',
                    icon: 'error',
                });
            } finally {
                setLoading(false);
            }
        } else {
            // User canceled the action
            Swal.fire('Cancelled', 'Your submission has been cancelled.', 'info');
        }
    };

    const extra_charges_total = rows1.reduce((total, row) => {
        const charge = parseFloat(row.total_amount) || 0;
        return total + charge;
    }, 0).toFixed(2);

    const total_price_without_gst = rows.reduce((total, row) => {
        const priceWithoutGst = parseFloat(row.price) || 0;
        const qty = parseFloat(row.qty) || 0;
        return total + (qty * priceWithoutGst);
    }, 0).toFixed(2);
    const total_price = (parseFloat(total_price_without_gst) + parseFloat(extra_charges_total)).toFixed(2);

    console.warn(rows);
    const other_charges_total = rowother.reduce((total, row) => {
        const charge = parseFloat(row.lable_charge) || 0;
        return total + charge;
    }, 0).toFixed(2);
    const c_gst_percentage = parseFloat(formData.c_gst) || 0;
    const s_gst_percentage = parseFloat(formData.s_gst) || 0;

    const total_c_gst = (parseFloat(total_price) * c_gst_percentage / 100).toFixed(2);
    const total_s_gst = (parseFloat(total_price) * s_gst_percentage / 100).toFixed(2);

    const total_amount = (parseFloat(total_price) + parseFloat(other_charges_total) + parseFloat(total_c_gst) + parseFloat(total_s_gst)).toFixed(2);
    const handleSubmitOrder = () => {
        let newErrors = {};


        // if (validateForm()) {
        // const total_price = rows.reduce((total, row) => {
        //     const priceWithGst = row.price * (1 + row.gst / 100);
        //     return total + (row.qty * priceWithGst);
        // }, 0).toFixed(2);

        const productsData = rows.map(row => ({
            category_id: row.category_id,
            id: row.id,
            order_id: row.order_id,
            order_id: parseInt(row.order_id),
            vender_id: row.vendor_id,
            product_id: row.product_id ? row.product_id.id : null,
            qty: row.qty,
            gst: row.gst,
            price: row.price,
        }));

        const OtherData = rowother
            .filter(row => row.tr_charge && row.lable_charge) // Only include valid rows
            .map(row => ({
                tr_charge: row.tr_charge,
                id: row.id,
                lable_charge: row.lable_charge,
                name: row.name,
            }));

        const ExtraData = rows1
            .filter(row => row.pro_qty && row.price && row.total_amount)
            .map(row => ({
                customer_id: data.id,
                category_id: row.category_id,
                vendor_id: row.vendor_id,
                name: row.name,
                id: row.id,
                pro_qty: row.pro_qty,
                price: row.price,
                total_amount: row.total_amount,
            }));

        const requestData = {
            ...formData,
            total_price,
            other_charges_total,
            total_c_gst,
            total_s_gst,
            total_amount,
            products: productsData,
            otherdata: OtherData.length > 0 ? OtherData : [],
            order_id: orderid,
            extra_products: ExtraData.length > 0 ? ExtraData : [],
            order_date: formData.order_date || new Date().toISOString().split('T')[0],
        };
        console.warn("formData", formData)
        handleSubmit(
            `${process.env.REACT_APP_API_URL}Order-update`,
            requestData,
            () => {
                // Success callback
            }
        );
        // } else {
        //     // Validation failed
        // }

    };

    // stock-Update APi
    const handleStockSubmit = async (url, formData, successCallback) => {
        setLoading(true);
        try {
            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            if (successCallback) successCallback(response.data);
        } catch (error) {
            console.error("Error in handleStockSubmit:", error);
        } finally {
            setLoading(false);
        }
    };
    const handleSubmitStockOrder = async () => {
        let calculateQty = 0;

        const EnterProOldQty = Number(EnterProQty) || 0;
        const EnterNewQty = Number(EnterQty) || 0;
        const EnterQtyStockproqtys = Number(EnterQtyStockproqty) || 0;

        const NewQty = EnterNewQty - EnterQtyStockproqtys;
        const newproqtyy = EnterProQty - NewQty
        calculateQty = newproqtyy.toString();
        //     }
        console.warn("calculateQtyyyy", calculateQty, EnterQty, EnterProQty, EnterQtyStockproqtys, NewQty);
        alert(calculateQty);

        if (isNaN(calculateQty) || calculateQty === "NaN") {
            return;
        }

        try {
            const requestData = {
                products: rows.map(row => ({
                    category_id: row.category_id,
                    vendor_id: row.vendor_id,
                    name: EnterQtyStockName,
                    pro_unit: row.pro_unit,
                    hsn_no: row.hsn_no,
                    pro_qty: calculateQty,
                    gst: row.gst,
                    sale_price_rate: row.price,
                    sale_price: row.sale_price,
                    total_amount: row.total_amount,
                    manufacture: row.manufacture,
                    id: EnterQtyStockId,
                    type: 'add_stock'
                })),
            };
            // console.log("requestDataaaaa",products);

            handleStockSubmit(
                `${process.env.REACT_APP_API_URL}Product-stock-update`,
                requestData,
                () => {
                    console.log("Order submitted successfully!");
                }
            );
        } catch (error) {
            console.error("Error in submitting the order: ", error);
            toast.error('An error occurred while processing the stock order.');
        }
    };



    // customer-payment Api

    // const handlePaymentSubmit = async () => {
    //     let calculatedAmount = 0;

    //     try {
    //         console.warn("newTotlllll", TotalQtyPrice, Totalamountnew)
    //         // Ensure numeric values are being used
    //         const totalAmount = Number(formData.total_amount) || 0;
    //         const enteredAmount = Number(formData.amount) || 0;
    //         const previousDue = Number(Dueamountold) || 0;
    //         const currentDue = Number(formData.due_amt) || 0;
    //         const TotalPrice = Number(TotalQtyPrice) || 0;

    //         // if(EnterQtyStockproqty < EnterQty){
    //         //     const newDueAmount = TotalPrice - enteredAmount;
    //         //     calculatedAmount = currentDue - newDueAmount;
    //         //     console.log("calculatedAmountttt", calculatedAmount);
    //         // } 
    //         // else{
    //         if (TotalcustomerDataamount < enteredAmount) {
    //             const newDueAmount = totalAmount - enteredAmount;
    //             calculatedAmount = currentDue - newDueAmount;
    //             console.warn("receive amt", newDueAmount, currentDue, totalAmount, calculatedAmount);
    //         } else {
    //             const newDueAmount = totalAmount - enteredAmount;
    //             const remainingDue = newDueAmount - previousDue;
    //             calculatedAmount = remainingDue + previousDue;
    //             console.warn("calculatedAmount",totalAmount, enteredAmount, previousDue, remainingDue, calculatedAmount);
    //             if (calculatedAmount == 0) {
    //                 return;
    //             }
    //         }
    //         // } 
    //         // Check if TotalcustomerDataamount exceeds formData.amount

    //         const response = await axios.post(
    //             `${process.env.REACT_APP_API_URL}Customer-payment-store`,
    //             {
    //                 customer_id: formData.customer_id,
    //                 account_id: formData.account_id,
    //                 amount: calculatedAmount,
    //                 mode: formData.account_mode,
    //                 // total_amount: totalAmount,
    //                 // currentDueAmount: currentDue,
    //             },
    //             {
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'x-access-token': token,
    //                 },
    //             }
    //         );

    //     } catch (error) {
    //         console.error('Error details:', error.response || error.message || error);
    //     }
    // };



    const filterSearchName = async (searchTerm) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}Order-search-list`, { customer_name: searchTerm }, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });

            if (response.data.status === 1) {
                setSearchNameList(response.data.data || []);
            } else {
                setSearchNameList([]);
            }
        } catch (error) {
            console.error('Error fetching search results:', error);
            setSearchNameList([]);
        }
    };
    const fetchCustomerDetails = async (id) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}Order-search-customer-list/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });

            const customerData = response.data.data[0];
            setFormData({
                order_date: customerData.order_date,
                customer_name: customerData.customer_name,
                customer_type: customerData.customer_type, // Assuming customer_type is fetched
                mobile_number: customerData.mobile_number,
                site_name: customerData.site_name,
                pincode: customerData.pincode,
                email: customerData.email,
                city: customerData.city,
                state: customerData.state,
                address1: customerData.address1,
                address2: customerData.address2,
                account_name: customerData.account_name,
                account_mode: customerData.account_mode,
                payer_name: customerData.payer_name,
                receiver_name: customerData.receiver_name,
            });
            setFilteredNameList([]);
        } catch (error) {
            console.error('Error fetching customer details:', error);
        }
    };
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}category-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                setCategories(response.data.data || []);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        if (!modalState.addproduct, rows) {
            fetchCategories();
            // setRows();        
        }
    }, [token, modalState.addproduct]);
    useEffect(() => {
        const fetchVendor = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}Vender-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                setVenderlist(response.data.data || []);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchVendor();
        // setRows();        

    }, [token]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const url = `${process.env.REACT_APP_API_URL}Order-view-product-list/${order_Update_id}`;
                const config = {
                    method: 'get',
                    url: url,
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                };
                const response = await axios(config);
                const { createOrder, product, extra_charges_store, product_other_data } = response.data.rows;
                // console.warn(product_other_data);

                // Assuming createOrder has only one item based on your example
                const orderDetails = createOrder[0];

                // setTotalData(orderDetails);
                setViewData({
                    ...orderDetails,
                    products: product,
                    extra_products: extra_charges_store,
                    otherData: product_other_data || []
                });
                const customerData = orderDetails;
                console.warn(customerData)
                setFormData({
                    customer_id: customerData.id,
                    account_id: customerData.account_id,
                    due_amt: customerData.due_amt,
                    order_date: customerData.order_date,
                    customer_name: customerData.customer_name,
                    customer_type: customerData.customer_type,
                    invoice_number_manual: customerData.invoice_number_manual,
                    description: customerData.description,
                    mobile_number: customerData.mobile_number,
                    site_name: customerData.site_name,
                    pincode: customerData.pincode,
                    email: customerData.email,
                    city: customerData.city,
                    state: customerData.state,
                    address1: customerData.address1,
                    address2: customerData.address2,
                    account_name: customerData.account_id,
                    account_mode: customerData.account_mode,
                    payer_name: customerData.payer_name,
                    receiver_name: customerData.receiver_name,
                    amount: customerData.amount,
                    total_price: customerData.total_price,
                    delivery_date: customerData.delivery_date,
                    manufacture: orderDetails.manufacture,
                    c_gst: orderDetails.c_gst,
                    s_gst: orderDetails.s_gst,
                    total_amount: orderDetails.total_amount,
                    gst_in_no: orderDetails.gst_in_no,
                    vehicle_number: orderDetails.vehicle_number,
                    // order_id_id: orderDetails.product[0].order_id,
                });

                setTotalcustomerDataamount(customerData.amount)
                setDueamount(customerData.due_amt)
                setTotalamount(orderDetails.total_amount)
                console.warn("customerDatadue_amtttttttttNewwww",Totalamountnew);
                if (product.length > 0) {
                    const updatedRows = product.map(item => ({
                        // category_id: { id: item.category_id, name: item.name },
                        id: item.product_order_id,
                        order_id: item.order_id,
                        vendor_id: item.vender_id,
                        category_id: item.category_id,
                        product_id: { id: item.product_id, name: item.name },
                        qty: item.qty,
                        pro_qty: item.pro_qty,
                        price: item.price,
                        gst: item.gst,
                        manufacture: item.manufacture,
                        pro_unit: item.pro_unit,
                        hsn_no: item.hsn_no,
                        total_amount: item.total_amount,
                        sale_price: item.sale_price,

                    }));

                    setRows(updatedRows);
                    console.warn("updatedRowsss", updatedRows)
                    if (updatedRows.length > 0) {
                        const { id, name } = updatedRows[0].product_id;
                        // const { qty } = updatedRows[0].qty;  
                        setEnterQtyStockId(id);
                        setEnterQtyStockName(name);
                        setEnterQtyStockpro_qty(updatedRows[0].qty);
                        console.warn("Old Qty", updatedRows[0].qty);
                    }

                }
                if (extra_charges_store.length > 0) {
                    const updatedRows = extra_charges_store.map(item => ({
                        id: item.id,
                        order_id: item.order_id,
                        customer_id: item.customer_id,
                        category_id: item.category_id,
                        name: item.name,
                        pro_qty: item.pro_qty,
                        price: item.price,
                        total_amount: item.total_amount,
                        vendor_id: item.vendor_id,
                        // vendor_id: { id: item.vendor_id, vendor_name: item.vendor_name },

                    }));
                    setRows1(updatedRows);
                }
                if (product_other_data.length > 0) {
                    const updatedRowsother = product_other_data && product_other_data.map(item => ({
                        tr_charge: item.tr_charge,
                        lable_charge: item.lable_charge,
                        id: item.id,
                        // name: item.name || '',
                    }));
                    setRowother(updatedRowsother);
                }
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        if (!modalState.addproduct) {
            fetchProducts();
            // setRows();        
        }
    }, [modalState.addproduct, token]);
    useEffect(() => {
        const fetchProducts1 = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}product-vieworder`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                setproductorder(response.data.data || []);

                // setStockId(response.data.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        if (!modalState.addraw || !modalState.addproduct) {
            fetchProducts1();
            // ProductView();
        }
    }, [modalState.addraw, modalState.addproduct, token]);

    const ProductView = async (id) => {
        setCurrentEditId(id);
        handleShowEditModal();
        try {
            let url = `${process.env.REACT_APP_API_URL}product-view/${id}`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            setViewdata(response.data.data || []);

        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        }
    };
    useEffect(() => {
        fetchAccountList();
    }, []);

    const fetchAccountList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}Account-list`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            setAccountList(response.data.data || []);
        } catch (error) {
            console.error('Error fetching employee list:', error);
        }
    };
    const handleDeleteProduct = (productId) => {
        const updatedProducts = productorderdata.filter(product => product.id !== productId);
        setproductorder(updatedProducts);

    };
    const accountModeOptions = [
        { value: 'Cash', label: 'Cash' },
        { value: 'Online', label: 'Online' },
        // { value: 'Either or Survivor Mode', label: 'Either or Survivor Mode' },
    ];

    const accountOptions = accountlist ? accountlist.map(employee => ({
        value: employee.id,
        label: employee.account_name
    })) : [];

    const handleDeleteRow = (index) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
    };
    const handleDeleteRowother = (index) => {
        const updatedRows = rowother.filter((_, i) => i !== index);
        setRowother(updatedRows);
    };
    const handleSearchItemClick = async (selectedName, customerId) => {
        setFormData({ ...formData, customer_name: selectedName });
        setSearchNameList([]);
        fetchCustomerDetails(customerId);
    };
    const goBack = () => {
        navigate('/orderview');
    };


    const orderTypeOptions = [
        { value: '', label: 'Select Order' },
        { value: 'invoice', label: 'Invoice' },
    ];

    const formatNumber = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return (
        <section className="takeorder_section">
            <ToastContainer />
            <div className="page-header  d-flex justify-content-between">
                <div className="page-title">
                    {/* <h4>Order</h4> */}
                    <h4>Create Order Update</h4>
                </div>
                <Button type="button" className="btn-added" onClick={goBack}> <FaArrowLeftLong style={{ paddingRight: '3px' }} />  Back</Button>

            </div>
            <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <Card.Title>Create Order Update</Card.Title>
                    {/* <Form>
                        <Form.Check
                            type="checkbox"
                            id="custom-switch"
                            label="GST"
                        />
                    </Form> */}
                </Card.Header>
                <Card.Body>
                    <div className="takeorder_heading">
                        <h4 className="mb-0">Customer Information</h4>
                    </div>
                    <Row>
                        {[
                            { label: "Date", name: "order_date", type: "date" },
                            { label: "Customer Type", name: "customer_type", type: "select", options: [{ value: "Retail", label: "Retail" }, { value: "Site", label: "Site" }, { value: "Design", label: "Design" }] },
                            { label: "Customer Name", name: "customer_name", type: "text" },
                            { label: "Mobile Number", name: "mobile_number", type: "text" },
                            { label: "Invoice Number", name: "invoice_number_manual", type: "number" },
                            { label: "Site Name", name: "site_name", type: "text" },
                            { label: "Pin Code", name: "pincode", type: "text" },
                            { label: "Email", name: "email", type: "email" },
                            { label: "City", name: "city", type: "text" },
                            { label: "State", name: "state", type: "text" },
                            { label: "Address One", name: "address1", type: "text" },
                            { label: "Address Two", name: "address2", type: "text" },
                        ].map(({ label, name, type, options }, idx) => (
                            <Col md={4} lg={4} key={idx}>
                                <Form.Group controlId={name} className='position-relative'>
                                    <Form.Label>{label}</Form.Label>
                                    {type === "select" ? (
                                        <Form.Select
                                            name={name}
                                            value={formData[name]}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors[name]}
                                        >
                                            <option value="">Select {label}</option>
                                            {options.map((option) => (
                                                <option key={option.value} value={option.value}>{option.label}</option>
                                            ))}
                                        </Form.Select>
                                    ) : (
                                        <Form.Control
                                            type={type}
                                            name={name}
                                            placeholder={`Enter ${label}`}
                                            value={formData[name] || (name === "order_date" ? new Date().toISOString().split('T')[0] : '')}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors[name]}
                                            onFocus={name === "order_date" ? (e) => e.target.showPicker() : null}
                                        />
                                    )}
                                    {name === "customer_name" && searchNameList.length > 0 && (
                                        <div className="search-results">

                                            {searchNameList.map((item, index) => (
                                                <div key={index} className="search-item" onClick={() => handleSearchItemClick(item.customer_name, item.id)}>
                                                    {item.customer_name}
                                                </div>
                                            ))}

                                        </div>
                                    )}

                                </Form.Group>
                            </Col>
                        ))}
                        <Col md={6} xs={12} lg={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="description"
                                    placeholder="Description"
                                    value={formData.description}
                                    onChange={handleInputChange}
                                // isInvalid={!!errors.amount}
                                />
                            </Form.Group>
                        </Col>
                        {/* <Col md={6} lg={6} xs={12}>
                            <Form.Group >
                                <Form.Label>Select Bill Type</Form.Label>
                                <Select options={orderTypeOptions}
                                    defaultValue={orderTypeOptions[0]}
                                    onChange={handleOrderTypeChange}
                                />

                            </Form.Group>
                        </Col> */}
                    </Row>
                    {selectedOrderType.value === 'invoice' && (
                        <Row>

                            <Col md={2} xs={2} lg={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Invoice Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="invoice_number_manual"
                                        placeholder="Invoice Number"
                                        value={formData.invoice_number_manual}
                                        onChange={handleInputChange}
                                    // isInvalid={!!errors.amount}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={2} xs={2} lg={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Vehicle Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="vehicle_number"
                                        placeholder="Vehicle Number"
                                        value={formData.vehicle_number}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={2} xs={2} lg={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>GSTIN No</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="gst_in_no"
                                        placeholder="GSTIN No"
                                        value={formData.gst_in_no}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                            {/* <Col md={2} xs={2} lg={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>CGST </Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="c_gst"
                                        placeholder="CGST "
                                        value={formData.c_gst}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={2} xs={2} lg={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>SGST </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="s_gst"
                                        placeholder="SGST "
                                        value={formData.s_gst}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col> */}
                        </Row>
                    )}
                </Card.Body>
            </Card>
            <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <Card.Title>Select Product</Card.Title>
                    {/* <Button onClick={() => setModalState({ ...modalState, addproduct: true })} className="btn-added addproduct">
                        <MdAddCircle /> Create Product
                    </Button> */}
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={12} xs={12} lg={12}>
                            <label><strong>Manufacture Type</strong></label>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex gap-2 align-items-center">
                                    <Form.Check
                                        type="radio"
                                        label="Trading"
                                        name="manufacture"
                                        id="product_type_trading"
                                        value="Trading"
                                        onChange={handleInputChange}
                                        checked={formData.manufacture === 'Trading'}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Manufacture"
                                        name="manufacture"
                                        id="product_type_manufacture"
                                        value="Manufacture"
                                        onChange={handleInputChange}
                                        checked={formData.manufacture === 'Manufacture'}
                                    />
                                </div>
                                <Button onClick={handleAddRow} className="btn-added addproduct">
                                    <MdAddCircle /> Add More
                                </Button>
                            </div>
                        </Col>

                    </Row>
                    {rows.map((row, index) => (
                        <Row key={index} className="mt-3">
                            <Col xs={12} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`category_id_${index}`}>
                                    <Form.Label>Product Category</Form.Label>
                                    <Select
                                        value={categoryOptions.find((option) => option.value == row.category_id)}
                                        options={categoryOptions}
                                        isSearchable={true}
                                        placeholder="Select"
                                        onChange={(selected) => handleRowChange(index, 'category_id', selected ? selected.value : null)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`product_name_${index}`}>
                                    <div className="d-flex justify-content-between">
                                        <Form.Label>Product Name</Form.Label>
                                    </div>
                                    <Select
                                        isMulti={false}
                                        className="basic-multi-select"
                                        options={getProductOptionsForRow(row.category_id, formData.manufacture)}
                                        value={row.product_id ? { value: row.product_id.id, label: row.product_id.name } : null}
                                        isSearchable={true}
                                        placeholder="Select"
                                        onChange={(selected) => handleRowChange(index, 'product_id', selected ? { id: selected.value, name: selected.label } : null)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`vendor_id_${index}`}>
                                    <Form.Label>Vendor Name</Form.Label>
                                    <Select
                                        value={vendorOptions.find((option) => option.value == row.vendor_id)}
                                        options={vendorOptions}
                                        isSearchable={true}
                                        placeholder="Select"
                                        onChange={(selected) => handleRowChange(index, 'vendor_id', selected ? selected.value : null)}
                                    />
                                </Form.Group>
                            </Col>
                            {/* <Col xs={12} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`qty_${index}`}>
                                    <Form.Label>QTY</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="qty"
                                        placeholder="QTY"
                                        value={row.qty}
                                        onChange={(e) => handleRowChange(index, 'qty', e.target.value)}
                                    />
                                </Form.Group>
                            </Col> */}
                            <Col xs={12} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`qty_${index}`}>
                                    <Form.Label>QTY</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="qty"
                                        placeholder="QTY"
                                        value={row.qty}
                                        onChange={(e) => handleRowChange(index, 'qty', e.target.value)}
                                        isInvalid={!!errors[`qty_${index}`]}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors[`qty_${index}`]}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            {/* <Col xs={1} md={4} lg={1}>
                                <Form.Group className="mb-3" controlId={`gst_${index}`}>
                                    <Form.Label>Gst</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="gst"
                                        placeholder="Gst"
                                        value={row.gst}
                                        onChange={(e) => handleRowChange(index, 'gst', e.target.value)}
                                    />
                                </Form.Group>
                            </Col> */}
                            <Col xs={2} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`price_${index}`}>
                                    <Form.Label>Unit Price</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="price"
                                        placeholder="Enter Price"
                                        value={formatNumber(row.price)}
                                        onChange={(e) => handleRowChange(index, 'price', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={1} xs={12} lg={1} className='d-flex align-items-center'>
                                <button
                                    className='btn-added'
                                    onClick={() => handleDeleteRow(index)}
                                >
                                    <RiDeleteBin6Line />
                                </button>
                            </Col>
                        </Row>
                    ))}
                </Card.Body>
            </Card>
            {rows.some(row => row.product_id) ? (
                <Card className='mt-2'>
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <Card.Title>Product Details</Card.Title>
                        {/* <Button onClick={() => setModalState({ ...modalState, addraw: true })} className="btn-added addproduct">
                            <MdAddCircle /> Add Raw
                        </Button> */}
                    </Card.Header>
                    <Card.Body>
                        <div className="table-responsive">
                            <Table striped bordered hover>
                                <thead>
                                    <tr className="thead-dark">
                                        <th>Sr No</th>
                                        <th>Product Name</th>
                                        <th>Unit</th>
                                        <th>Qty</th>
                                        <th>Sale Price</th>
                                        <th>Gst In</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productorderdata && productorderdata.length > 0 ? (
                                        productorderdata
                                            .filter(item => rows.some(row => row.product_id && row.product_id.id == item.id))
                                            .map((item, index) => (
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.pro_unit}</td>
                                                    <td>{item.pro_qty}</td>
                                                    <td>{new Intl.NumberFormat().format(item.sale_price)}</td>
                                                    <td>{item.gst}</td>
                                                    <td className="edit-delete-action justify-content-start d-flex gap-2">
                                                        {/* <Link to="javascript:void(0)" style={{ "--i": "red" }} className="p-2" onClick={() => handleDeleteProduct(item.id)}>
                                                            <MdDelete />
                                                        </Link> */}
                                                        <Link to="#" style={{ "--i": "#233882" }} className="p-2" onClick={() => ProductView(item.id)}><FaEye /></Link>
                                                        {/* <Link to="javascript:void(0)" style={{ "--i": "red" }} className="p-2" onClick={() => setModalState({ ...modalState, addraw: true })}>
                                                            <MdAddCircle />Add Raw
                                                        </Link> */}
                                                    </td>
                                                </tr>
                                            ))
                                    ) : (
                                        <tr>
                                            <td colSpan="7" className='text-center'>
                                                <span>Select Product Name</span>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </Card.Body>
                </Card>
            ) : null}


            <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <Card.Title>Other Charges</Card.Title>
                    {/* <Button onClick={handleAddRowOther} className="btn-added addproduct">
                        <MdAddCircle /> Add More
                    </Button> */}
                </Card.Header>
                <Card.Body>
                    {rowother.map((row, index) => (
                        <Row key={index} className="mt-3">
                            <Col md={2} xs={12} lg={3}>
                                <Form.Group className="mb-3" controlId={`price_${index}`}>
                                    {/* <Form.Label></Form.Label> */}
                                    <Form.Control
                                        type="text"
                                        name="tr_charge"
                                        placeholder="Name"
                                        value={row.tr_charge}
                                        onChange={(e) => handleRowChangeOther(index, 'tr_charge', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>


                            <Col md={2} xs={12} lg={4}>
                                <Form.Group className="mb-3" controlId={`price_${index}`}>
                                    {/* <Form.Label></Form.Label> */}
                                    <Form.Control
                                        type="text"
                                        name="lable_charge"
                                        placeholder="Value"
                                        value={row.lable_charge}
                                        onChange={(e) => handleRowChangeOther(index, 'lable_charge', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            {/* <Col md={2} xs={12} lg={4}>
                                <Form.Group className="mb-3" controlId={`price_${index}`}>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder="Name"
                                        value={row.name}
                                        onChange={(e) => handleRowChangeOther(index, 'name', e.target.value)}
                                    />
                                </Form.Group>
                            </Col> */}
                            <Col md={1} xs={12} lg={1} className='d-flex align-items-center'>
                                <button
                                    className='btn-added'
                                    onClick={() => handleDeleteRowother(index)}
                                >
                                    <RiDeleteBin6Line />
                                </button>
                            </Col>

                        </Row>
                    ))}

                </Card.Body>
            </Card>


            {/* <Button>Extra Charges Add</Button> */}
            {data.order_type === 'product' ? (
                <Card>
                    <Card.Header>
                        <h3 className="card-title mb-0">Extra Work Add</h3>
                    </Card.Header>
                    <Card.Body>
                        <div className=" d-flex w-100 justify-content-end">
                            <Button onClick={handleAddRow1} className="mb-3">Add More</Button>
                        </div>
                        {rows1.map((row, index) => (
                            <Row key={index} className="mb-3">
                                {/* <Col xs={12} md={4}>
                             <Form.Group controlId={`customer_id_${index}`}>
                                 <Form.Label>Customer Name</Form.Label>
                                 <Select
                                     value={customerOptions.find(option => option.value === row.customer_id)}
                                     options={customerOptions}
                                     onChange={selected => handleRowChange(index, 'customer_id', selected ? selected.value : null)}
                                 />
                             </Form.Group>
                         </Col> */}
                                <Col xs={12} md={2}>
                                    <Form.Group controlId={`category_id_${index}`}>
                                        <Form.Label>Product Category</Form.Label>
                                        <Select
                                            value={extra_categoryOptions.find(option => option.value == row.category_id)}
                                            options={extra_categoryOptions}
                                            onChange={selected => handleRowChangeExtra(index, 'category_id', selected ? selected.value : null)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={2}>
                                    <Form.Group controlId={`vendor_id_${index}`}>
                                        <Form.Label>Vendor Name</Form.Label>
                                        <Select
                                            value={vendorOptions1.find(option => option.value == row.vendor_id)}
                                            options={vendorOptions1}
                                            onChange={selected => handleRowChangeExtra(index, 'vendor_id', selected ? selected.value : null)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={2}>
                                    <Form.Group controlId={`name_${index}`}>
                                        <Form.Label>Product Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={row.name}
                                            onChange={e => handleRowChangeExtra(index, 'name', e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={1}>
                                    <Form.Group controlId={`pro_qty_${index}`}>
                                        <Form.Label>Qty</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={row.pro_qty}
                                            onChange={e => handleRowChangeExtra(index, 'pro_qty', e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={2}>
                                    <Form.Group controlId={`price_${index}`}>
                                        <Form.Label>Price</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={row.price}
                                            onChange={e => handleRowChangeExtra(index, 'price', e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={2}>
                                    <Form.Group controlId={`total_amount_${index}`}>
                                        <Form.Label>Total Amount</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={row.total_amount}
                                            readOnly
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={1} className="d-flex align-items-center">
                                    <Button variant="danger" onClick={() => handleDeleteRow1(index)}>
                                        <RiDeleteBin6Line />
                                    </Button>
                                </Col>
                            </Row>
                        ))}
                        <div className=" d-flex w-100 justify-content-end">
                            {/* <Button onClick={handleSubmitOrder} disabled={loading}>
                         {loading ? 'Saving...' : 'Create'}
                     </Button> */}
                        </div>
                    </Card.Body>
                </Card>
            ) : null}
            <Card>
                <Card.Body>
                    <Row>
                        <>
                            {data.order_type === 'invoice' ? (
                                <>
                                    <Col md={2} xs={2} lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>CGST %</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="c_gst"
                                                placeholder="CGST %"
                                                value={formData.c_gst}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2} xs={2} lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>SGST %</Form.Label>
                                            <Form.Control
                                                type="number" // should be 'number' if it’s a percentage input
                                                name="s_gst"
                                                placeholder="SGST %"
                                                value={formData.s_gst}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </>
                            ) : null}
                            <Col md={2} lg={2} col={12}>
                                <Form.Label>Total price</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="total_price"
                                    placeholder="Enter Total Price"
                                    disabled
                                    // value={(rows.reduce((total, row) => {
                                    //     // Ensure row.price, row.gst, row.qty are valid numbers
                                    //     const price = parseFloat(row.price) || 0;
                                    //     const gst = parseFloat(row.gst) || 0;
                                    //     const qty = parseFloat(row.qty) || 0;

                                    //     // Calculate price with GST
                                    //     const priceWithGst = price * (1 + gst / 100);

                                    //     // Return the accumulated total, adding price for this row
                                    //     return total + (qty * priceWithGst);
                                    // }, 0) + parseFloat(extra_charges_total || 0)).toFixed(2)}  // Adding extra_charges_total
                                    value={new Intl.NumberFormat().format(total_price)}
                                    readOnly
                                    onChange={handleInputChange}
                                />
                            </Col>


                            {selectedOrderType.value === 'invoice' && (
                                <Col md={2} xs={2} lg={2}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Cgst </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="total_c_gst"
                                            placeholder="CGST "
                                            disabled
                                            value={total_c_gst}
                                            readOnly
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                            )}
                            {selectedOrderType.value === 'invoice' && (
                                <Col md={2} xs={2} lg={2}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Sgst </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="total_s_gst"
                                            placeholder="SGST "
                                            disabled
                                            value={total_s_gst}
                                            readOnly
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                            )}
                            <Col md={2} lg={2} col={12}>
                                <Form.Label>Total Amount</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="total_price"
                                    placeholder="Enter Total Price"
                                    disabled
                                    value={new Intl.NumberFormat().format(total_amount)}
                                    readOnly
                                />
                            </Col>

                            <Col md={3} lg={3} col={12}>
                                <Form.Group controlId="delivery_date">
                                    <Form.Label>Delivery Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="delivery_date"
                                        placeholder="Delivery Date"
                                        value={formData.delivery_date}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors.delivery_date}
                                        onFocus={(e) => e.target.showPicker()}
                                    />

                                </Form.Group>
                            </Col>
                        </>
                    </Row>
                </Card.Body>
            </Card>
            <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <Card.Title>Account</Card.Title>

                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={4} lg={4} col={12}>
                            <Form.Group controlId="amount">
                                <Form.Label>Enter Amount</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="amount"
                                    placeholder="Enter Amount"
                                    value={formData.amount}
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.amount}
                                />
                            </Form.Group>

                        </Col>
                        <Col md={4} lg={4} xs={12}>
                            <Form.Group controlId="account_name">
                                <Form.Label>Select Account</Form.Label>
                                <Select
                                    value={accountOptions && accountOptions.find(option => option.value == formData.account_name)}
                                    onChange={(selected) => handleSelectChange('account_name', selected)}
                                    options={accountOptions}
                                />


                            </Form.Group>
                        </Col>


                        <Col md={4} lg={4} col={12}>
                            <Form.Group controlId="account_mode">
                                <Form.Label>Select Mode</Form.Label>
                                <Select
                                    value={accountModeOptions.find(option => option.value == formData.account_mode)}
                                    onChange={(selected) => handleSelectChange('account_mode', selected)}
                                    options={accountModeOptions}

                                />
                            </Form.Group>
                        </Col>

                        <Col md={4} lg={4} col={12}>
                            <Form.Group controlId="receiver_name">
                                <Form.Label>Receiver Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="receiver_name"
                                    placeholder="Receiver Name"
                                    value={formData.receiver_name}
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.receiver_name}
                                />

                            </Form.Group>
                        </Col>
                        <Col md={4} lg={4} col={12}>
                            <Form.Group controlId="payer_name">
                                <Form.Label>Payer Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="payer_name"
                                    placeholder="Payer Name"
                                    value={formData.payer_name}
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.payer_name}
                                />

                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <div className="d-flex justify-content-end">
                <div className="d-flex gap-1">
                    <Button className="btn takeorder" disabled={loading} onClick={handleSubmitOrder} >
                        {loading ? 'Saving...' : 'Create Order Update'}
                    </Button>
                </div>
            </div>
            <Modal
                size="xl"
                show={modalState.addproduct}
                onHide={() => setModalState({ ...modalState, addproduct: false })}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <h3 className="card-title mb-0">Add Product</h3>
                </Modal.Header>

                <Modal.Body closeButton>
                    <Addproduct onSave={() => setModalState({ ...modalState, addproduct: false })} />
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={modalState.addraw}
                onHide={() => setModalState({ ...modalState, addraw: false })}
                aria-labelledby="example-modal-sizes-title-lg"
            >

                <Modal.Body closeButton>
                    <AddproductRaw onSave={() => setModalState({ ...modalState, addraw: false })} />
                </Modal.Body>
            </Modal>
            <Modal show={showEditModal} size="lg" onHide={handleCloseEditModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h4>View Details</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive">
                        {/* <h6 className="fw-bold">Product details</h6> */}
                        <table className="table">
                            <tbody>
                                {viewdata.map((item) => (
                                    <React.Fragment key={item.id}>


                                        <tr>
                                            <td colSpan="6">
                                                <h6 className="fw-bold">Row Materials</h6>
                                                <table className="table">
                                                    <thead className='thead-primary'>
                                                        <tr>
                                                            {/* <th>Id</th> */}
                                                            <th>Name</th>
                                                            <th>Qty</th>
                                                            <th>Unit Cost Price</th>
                                                            <th>Unit Sale Price</th>
                                                            <th>Cost Price</th>
                                                            <th>Sale Price</th>
                                                            <th>Vendor Name</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {item.row_materials.map(material => (
                                                            <tr key={material.id}>
                                                                {/* <td>{material.id}</td> */}
                                                                <td>{material.raw_name}</td>
                                                                {/* <td>demo</td> */}
                                                                <td>{material.qty}</td>
                                                                <td>{material.u_cost_prize}</td>
                                                                <td>{material.unit_sale_price}</td>
                                                                <td>{material.Costprice}</td>
                                                                <td>{material.Saleprice}</td>
                                                                <td>{material.vender_Name}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="6">
                                                <h6 className="fw-bold">Attributes</h6>
                                                <table className="table">
                                                    <thead className='thead-info'>
                                                        <tr>
                                                            <th>Id</th>
                                                            <th>Name</th>
                                                            <th>Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {item.attributes.map(attr => (
                                                            <tr key={attr.id}>
                                                                <td>{attr.id}</td>
                                                                <td>{attr.name}</td>
                                                                <td>{attr.value}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </Modal.Body>

            </Modal>
        </section >
    );
}
