import React, { useState, useEffect, useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DataTable from './Stocktable';
import axios from 'axios';
import { FaEye, FaPlus, FaEdit } from "react-icons/fa";
import { useNavigate, Link } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
const StockManagement = () => {
    const token = localStorage.getItem('token');
    const Le_orderid = localStorage.getItem('Le_orderid');
    const navigate = useNavigate();
    const [companyDetails, setCompanyDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const [data, setVendorData] = useState([]);

    useEffect(() => {
        fetchVendor();
    }, [token]);

    const fetchVendor = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}Site-ledger-list/${Le_orderid}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            // Set the data from OtherData array if available
            const otherData = response.data.data || [];
            setVendorData(otherData);
        } catch (error) {
            console.error('Error fetching vendors:', error);
        }
    };
    const handleView = (order2) => {
        localStorage.setItem('ven_ledger_id', order2);
        navigate('/Vendor-Ledger-Customer-payment');
    };
    const handleViewCustomerpayment = (order) => {
        // localStorage.setItem('orderproduct_id', order);
        navigate('/Site-Ledger-Customer-payment');
    };
    const handleViewExpenditurepayment = (order) => {
        // localStorage.setItem('orderproduct_id', order);
        navigate('/Expenditure-Ledger-Customer-payment');
    };
    useEffect(() => {
        const fetchCompanyDetails = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}Company-details-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                if (response.data.status === 1) {
                    setCompanyDetails(response.data.data[0]);
                }
            } catch (error) {
                console.error('Error fetching company details:', error);
            }
        };

        fetchCompanyDetails();
    }, [token]);
    const handleView1 = (id) => {
        localStorage.setItem('orderproduct_ladger_id', id);
        navigate('/Order-Product-View-Ladger');
    };
    const downloadPDF = () => {
        const doc = new jsPDF();
        // Add company details to the top of the PDF
        doc.text('Site Ledger Report', 14, 16);
        doc.text(companyDetails.company_name, 14, 25);
        doc.text(`${companyDetails.company_address}, ${companyDetails.state}, ${companyDetails.city} - ${companyDetails.pincode}`, 14, 30);
        doc.text(`MOB NO.- ${companyDetails.mobile}`, 14, 35);

        // Add balance details
        // doc.text(`Total Amount: ₹${new Intl.NumberFormat().format(data.total_amount)}`, 14, 45);
        // doc.text(`Expenditure Amount: ₹${new Intl.NumberFormat().format(data.expenditure_amount)}`, 14, 50);
        // doc.text(`Available Balance: ₹${new Intl.NumberFormat().format(data.total_amount - (data.expenditure_amount + (data.OtherData ? data.OtherData.reduce((sum, vendor) => sum + vendor.vendorTotal, 0) : 0)))}`, 14, 55);

        // Define table columns (keys)
        const tableColumn = ["Sr. No", "Name", "Vch Type", "Debit", "Credit", "Amount"];
        const tableRows = [];

        // Add customer row (first row)
        tableRows.push([

            '',
            data.customer_name,
            "Sale",
            '',
            '',
            data.total_amount,
            ''
        ]);

        tableRows.push([
            1,
            data.customer_name,
            "Receive",
            '',
            data.amount,
            '',
            ''
        ]);

        tableRows.push([
            2,
            "Expenditure",
            "Payment",
            data.expenditure_amount,
            '',
            ''
        ]);

        data.OtherData && data.OtherData.forEach((vendor, index) => {
            tableRows.push([
                index + 3,
                vendor.vendor_name,
                "Payment",
                vendor.vendorTotal,
                '',
                ''
            ]);
        });

        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 65,
        });

        // Save the PDF
        doc.save('site_ledger_report.pdf');
    };


    return (
        <>
            <div className="App">
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex">
                            <div className="w-25 mx-auto">
                                <div className="text-center">
                                    <h6 className='fw-bold mb-0'>{companyDetails.company_name}</h6>
                                    <p className='addresslink'>
                                        {companyDetails.company_address}, {companyDetails.state}, {companyDetails.city} - {companyDetails.pincode}
                                    </p>
                                    <a href="#" className='text-dark text-underlinetag'>MOB NO.- {companyDetails.mobile}</a>
                                </div>
                            </div>
                            <div className="gap-1">
                                <Button onClick={downloadPDF} className="btn btn-secondary gap-2">PDF</Button>
                            </div>
                            <div className="gap-1"><Link to='/orderview' className='btn-added'>Back</Link></div>
                        </div>
                    </div>

                    <div className="card-body">
                        <table className='table table-striped hover border'>
                            <thead className='thead-dark '>
                                <tr className='mb-2' onClick={() => handleView1(data.id)}>
                                    <td></td>
                                    <td className='fw-bold mb-0 text-red'>{data.customer_name}</td>
                                    <td>Sale: {new Intl.NumberFormat().format(data.total_amount)}</td>
                                    <td>Payment: {new Intl.NumberFormat().format(data.expenditure_amount + (data.OtherData ? data.OtherData.reduce((sum, vendor) => sum + vendor.vendorTotal, 0) : 0))}</td>
                                    {/* <td>Closing Balance: {new Intl.NumberFormat().format(data.total_amount - (data.expenditure_amount + (data.OtherData ? data.OtherData.reduce((sum, vendor) => sum + vendor.vendorTotal, 0) : 0)))}</td> */}
                                    <td>Closing Balance: {new Intl.NumberFormat().format(data.amount - data.total_amount)}</td>
                                    <td><Button onClick={() => handleView1(data.id)} variant="secondary" className="ms-2"><FaEye /></Button></td>
                                </tr>
                                <tr>
                                    <th>Sr.no</th>
                                    <th>Name</th>
                                    <th>Vch Type</th>
                                    <th>Debit</th>
                                    <th>Credit</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className='trcolorchange'>
                                <tr onClick={() => handleViewCustomerpayment()}>
                                    <td className='text-dark font-weight-bold' >1</td>
                                    <td className='text-dark font-weight-bold'>{data.customer_name}</td>
                                    <td className='text-dark font-weight-bold'>Receive</td>
                                    <td className='text-dark font-weight-bold'></td>
                                    <td className='text-success font-weight-bold'>
                                        {new Intl.NumberFormat().format(data.amount)}
                                    </td>
                                    <td><Button onClick={() => handleViewCustomerpayment()} variant="secondary" className="ms-2"><FaEye /></Button></td>
                                </tr>

                                <tr onClick={() => handleViewExpenditurepayment()}>
                                    <td>2</td>
                                    <td>Expenditure</td>
                                    <td>Payment</td>
                                    <td>{new Intl.NumberFormat().format(data.expenditure_amount)}</td>
                                    <td></td>
                                    <td><Button onClick={() => handleViewExpenditurepayment()} variant="secondary" className="ms-2"><FaEye /></Button></td>
                                </tr>

                                {/* Looping through OtherData for vendors */}
                                {data.OtherData && data.OtherData.map((vendor, index) => (
                                    <tr key={vendor.vendor_id} onClick={() => handleView(vendor.vendor_id)}>
                                        <td>{index + 3}</td>
                                        <td>{vendor.vendor_name}</td>
                                        <td>Payment</td>
                                        <td>{new Intl.NumberFormat().format(vendor.vendorTotal)}</td>
                                        <td></td>
                                        <td><Button variant="secondary" onClick={() => handleView(vendor.vendor_id)} className="ms-2"><FaEye /></Button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="d-flex justify-content-end">
                            <div className="totoalamount">
                                <div className='text-start mb-1'><strong>Customer Balance:</strong></div>
                                <div><span><strong>Total Amount:</strong></span> {new Intl.NumberFormat().format(data.total_amount)}</div>
                                <div><span><strong>Receive Amount:</strong></span> {new Intl.NumberFormat().format(data.amount)}</div>
                                <div><span><strong className='text-red'>Due Amount:</strong></span> {new Intl.NumberFormat().format(data.amount - data.total_amount)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StockManagement;
