
import { BrowserRouter as Router, Routes, Route, BrowserRouter } from 'react-router-dom';
import Sidebar from './Component/Sidebar';
import Navbar from './Component/Navbar';
import Home from './Pages/Home';
import Login from './Login/Login';
import Venderreport from './Pages/Venderreport'
import Rawmatrial from './Pages/Rawmatrial'
import Unit from './attribute/Unit'
import Attribute from './attribute/Attribute'
import Category from './Pages/Category'
import Order from './Pages/Order'
import CustomerChart from './Pages/CustomerCharts'
import VendorChart from './Pages/VenderChart'
import PieChart from './Pages/PieChart'
import Forgetpassword from './Pages/Forgetpassword';
import Register from './Login/Register'
import Productmodule from './Productmodule/Productmodule';
// import Employeelist from './Pages/Employeelist'
import Employeelist from './Pages/employeeManagement/Employeelist'
import Accountlist from './Pages/accountManagement/Accountlist'
import Account_Ladger from './Pages/accountManagement/Account_Ladger'
import Expenditure_Type from './Pages/expenditure/Expenditure_Type'
import AddExpenditure from './Pages/expenditure/AddExpenditure'
import Expenditurelist from './Pages/expenditure/Expenditurelist'
import Expenditure_month_amount from './Pages/expenditure/Expenditure_month_amount'
import Expenditure_type_month_Amount from './Pages/expenditure/Expenditure_type_month_Amount'
import Expenditure_Amountview from './Pages/expenditure/Expenditure_Amountview'
import Addattribute from './attribute/Addattribute'
import Addrowmaterial from './Pages/Addrowmaterial';
import AddVendors from './Vendermanagement/AddVendors';
import OrderProcessDetails from './Vendermanagement/OrderProcessDetails';
import Vendermanagement from './Vendermanagement/Vendermanagement';
import Vendorprofile from './Vendermanagement/Vendorprofile';
import VendorUpdate from './Vendermanagement/VendorUpdate';
import Vendorview from './Vendermanagement/Vendorview';
import OrderDetails from './Vendermanagement/OrderDetails';
import Ven_Ladger from './Vendermanagement/Ven_Ladger';
import Venderpayment from './Vendermanagement/Venderpayment';
import Ven_Ladger_Payment_List from './Vendermanagement/Ven_Ladger_Payment_List';
import Ven_Ladger_Stock_Details_List from './Vendermanagement/Ven_Ladger_Stock_Details_List';
import Ven_Ladger_Material_Details_List from './Vendermanagement/Ven_Ladger_Material_Details_List';
import Ven_Ladger_Details_List from './Vendermanagement/Ven_Ladger_Details_List';

import Addproduct from './Productmodule/Addproduct';
import ProductUpdate from './Productmodule/ProductUpdate';
import Addstock from './Stock/Addstock'
import ExtraCharges from './Stock/ExtraCharges'
import ExtraChargesList from './Stock/ExtraChargesList'
import Stocktable from './Stock/Stocktable'
import Stockmangement from './Stock/Stockmangement'
import AddstockUpdate from './Stock/AddstockUpdate'
import Takeorder from './Takeorder/Takeorder'
import Orderview from './Takeorder/Orderview'
import Site_Ladger from './Takeorder/SiteLadgers/Site_Ladger'
import Customer_payment_Ladger_Details from './Takeorder/SiteLadgers/Customer_payment_Ladger_Details'
import Expenditure_payment_Ladger_Details from './Takeorder/SiteLadgers/Expenditure_payment_Ladger_Details'
import Vendor_payment_Ladger_Details from './Takeorder/SiteLadgers/Vendor_payment_Ladger_Details'
import OrderInvoicePdf from './Takeorder/OrderInvoicePdf'
import OrderProductPdf from './Takeorder/OrderProductPdf'
import OrderDesignPdf from './Takeorder/OrderDesignPdf'
import OrderBillSupplyPdf from './Takeorder/OrderBillSupplyPdf'
import OrderProductView from './Takeorder/OrderProductView'
import OrderProductView_Ladger from './Takeorder/SiteLadgers/OrderProductView_Ladger'
import OrderProductPdfDownload from './Takeorder/OrderProductPdfDownload'
import ReceiveMaterialsList from './Takeorder/ReceiveMaterial/ReceiveMaterialsList';
import Stockmaterial from './Takeorder/ReceiveMaterial/Stockmaterial';
import Godaven_List from './Takeorder/ReceiveMaterial/Godaven_List';
import OrderProductRawMaterialView from './Takeorder/Venderrecive/OrderProductRawMaterialView'
import CustumerProductview from './Takeorder/Venderrecive/CustumerProductview'
import Createaccount from './Accountmangement/Createaccount'
import Leadmangement from './Leadmangement/Leadmangement'
import Leadfollowlist from './Leadmangement/Leadfollowlist'
import VendorProductdetails from './Takeorder/Venderrecive/VenderProductdetails'
import Generate from './Takeorder/Venderrecive/OrdertakeProcessing/Generate'
import Shipment from './Takeorder/Venderrecive/OrdertakeProcessing/Shipment'
import DueView from './Takeorder/Orderdetails/DueView';
import ReceiveView from './Takeorder/Orderdetails/ReceiveView';
import TakeorderUpdate from './Takeorder/TakeorderUpdate'
import CompanyDetails from './Pages/CompanyDetails';
import PendingOrder from './Pages/DashboardPages/PendingOrder';
import TodayOrder from './Pages/DashboardPages/TodayOrder';
import UpcomingOrder from './Pages/DashboardPages/UpcomingOrder';
import PieDesign from './Pages/DashboardPages/PieDesign';

function App() {

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/*" element={
            <div className="d-flex">
              <Sidebar />
              <div className='page-wrapper'>
                {/* <Navbar /> */}
                <main className="content">
                  <Routes>
                    <Route path="/home" element={<Home />} />
                    <Route path="/forgetpassword" element={<Forgetpassword />} />
                    <Route path="/PendingOrder" element={<PendingOrder />} />
                    <Route path="/Order-Invoice-Pdf" element={<OrderInvoicePdf />} />
                    <Route path="/Order-Product-Pdf" element={<OrderProductPdf />} />
                    <Route path="/Order-BillSupply-Pdf" element={<OrderBillSupplyPdf />} />
                    <Route path="/Order-Design-Pdf" element={<OrderDesignPdf />} />
                    <Route path="/TodayOrder" element={<TodayOrder />} />
                    <Route path="/UpcomingOrder" element={<UpcomingOrder />} />
                    <Route path="/PieDesign" element={<PieDesign />} />
                    <Route path="/company_details" element={<CompanyDetails />} />
                    <Route path="/venderreport" element={<Venderreport />} />
                    <Route path="/rawmatrial" element={<Rawmatrial />} />
                    <Route path="/CustomerChart" element={<CustomerChart />} />
                    <Route path="/VendorChart" element={<VendorChart />} />
                    <Route path="/PieChart" element={<PieChart />} />
                    <Route path="/Order" element={<Order />} />
                    <Route path="/category" element={<Category />} />
                    <Route path="/unit" element={<Unit />} />
                    <Route path="/vendermanagement" element={<Vendermanagement />} />
                    <Route path="/employeelist" element={<Employeelist />} />
                    <Route path="/Account-list" element={<Accountlist />} />
                    <Route path="/Expenditure-Type" element={<Expenditure_Type />} />
                    <Route path="/Add-Expenditure" element={<AddExpenditure />} />
                    <Route path="/Expenditure-list" element={<Expenditurelist />} />
                    <Route path="/Expenditure-month-amount" element={<Expenditure_month_amount />} />
                    <Route path="/Expenditure_Totalmonth_Amount" element={<Expenditure_type_month_Amount />} />
                    <Route path="/Expenditure-Amountview" element={<Expenditure_Amountview />} />
                    <Route path="/productmodule" element={<Productmodule />} />
                    <Route path="/addrowmaterial" element={<Addrowmaterial />} />
                    <Route path="/attribute" element={<Attribute />} />
                    <Route path="/addstock" element={<Addstock />} />
                    <Route path="/Extra-charges" element={<ExtraCharges />} />
                    <Route path="/Extra-charges-list" element={<ExtraChargesList />} />
                    <Route path="/stocktable" element={<Stocktable />} />
                    <Route path="/addproduct" element={<Addproduct />} />
                    <Route path="/Product-Update" element={<ProductUpdate />} />
                    <Route path="/addattribute" element={<Addattribute />} />
                    <Route path="/stockmangement" element={<Stockmangement />} />
                    <Route path="/Add-Stock-Update" element={<AddstockUpdate />} />
                    <Route path="/Add-Vendors" element={<AddVendors />} />
                    <Route path="/Order-Process-Details" element={<OrderProcessDetails />} />
                    <Route path="/Vendor-Update" element={<VendorUpdate />} />
                    <Route path="/takeorder" element={<Takeorder />} />
                    <Route path="/orderview" element={<Orderview />} />
                    <Route path="/Site-Ledger" element={<Site_Ladger />} />
                    <Route path="/Site-Ledger-Customer-payment" element={<Customer_payment_Ladger_Details />} />
                    <Route path="/Expenditure-Ledger-Customer-payment" element={<Expenditure_payment_Ladger_Details />} />
                    <Route path="/Vendor-Ledger-Customer-payment" element={<Vendor_payment_Ladger_Details />} />
                    <Route path="/ReceiveView" element={<ReceiveView />} />
                    <Route path="/DueView" element={<DueView />} />
                    <Route path="/Order-Product-View" element={<OrderProductView />} />
                    <Route path="/Order-Product-View-Ladger" element={<OrderProductView_Ladger />} />
                    <Route path="/Order-Product-Pdf-Download" element={<OrderProductPdfDownload />} />
                    <Route path="/Receive-Materials" element={<ReceiveMaterialsList />} />
                    <Route path="/Receive-Materials-Stock" element={<Stockmaterial />} />
                    <Route path="/Godaven-List" element={<Godaven_List />} />
                    <Route path="/Order-Product-RawMaterial-View" element={<OrderProductRawMaterialView />} />
                    <Route path="/Custumer-Product-view" element={<CustumerProductview />} />
                    <Route path="/createaccount" element={<Createaccount />} />
                    <Route path="/Account-ladger" element={<Account_Ladger />} />
                    <Route path="/vendorProductdetails" element={<VendorProductdetails />} />
                    <Route path="/leadmangement" element={<Leadmangement />} />
                    <Route path="/leadfollowlist" element={<Leadfollowlist />} />
                    <Route path="/Vendorview" element={<Vendorview />} />
                    <Route path="/Take-order-update" element={<TakeorderUpdate />} />
                    <Route path="/Order-Details" element={<OrderDetails />} />
                    <Route path="/generate" element={<Generate />} />
                    <Route path="/Vendorprofile" element={<Vendorprofile />} />
                    <Route path="/shipment" element={<Shipment />} />
                    <Route path="/vendor-ladgor" element={<Ven_Ladger />} />
                    <Route path="/vendor-ladgor-payment" element={<Ven_Ladger_Payment_List />} />
                    <Route path="/vendor-ladgor-Stock-List" element={<Ven_Ladger_Stock_Details_List />} />
                    <Route path="/vendor-ladgor-Meterils-List" element={<Ven_Ladger_Material_Details_List />} />
                    <Route path="/vendor-ladgor-Details" element={<Ven_Ladger_Details_List />} />
                    <Route path="/vendor-payment" element={<Venderpayment />} />


                  </Routes>

                </main>
              </div>
            </div>

          }>

          </Route>
        </Routes>

      </Router>
    </>
  );
}

export default App;
