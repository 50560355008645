import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';


const useFormFields = (initialFields) => {
    const [fields, setFields] = useState(initialFields);


    const [errors, setErrors] = useState(
        Object.keys(initialFields).reduce((acc, key) => ({ ...acc, [key]: false }), {})
    );

    const handleFieldChange = (key) => (e) => {
        let value = e.target.value;
        setFields({ ...fields, [key]: value });
        setErrors({ ...errors, [key]: false });

    };

    return { fields, errors, handleFieldChange, setFields, setErrors };
};


const validateFields = (fields, setErrors) => {
    const newErrors = {};
    let isValid = true;

    if (!fields.AccountType) {
        newErrors.AccountType = true;
        isValid = false;
    }
    if (!fields.AccountName) {
        newErrors.AccountName = true;
        isValid = false;
    }
    if (!fields.CustomerName) {
        newErrors.CustomerName = true;
        isValid = false;
    }

    setErrors(newErrors);
    return isValid;
};

const VendorForm = ({ onSave }) => {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");


    const initialFields = {
        selectedCustomerId: '',
        Amount: '',
        AccountName: '',
        AccountType: '',
        Date: '',
        
    };

    const { fields, errors, handleFieldChange, setFields, setErrors } = useFormFields(initialFields);
    const [loading, setLoading] = useState(false);
    const [employeeList, setEmployeeList] = useState([]);
    const [custumerlist, setCustumerlist] = useState([]);
    const [selectedCustomerId, setselectedCustomerId] = useState(null);
    const [selectedAccountId, setselectedAccountId] = useState(null);
    const [TotalAmt, setTotalAmt] = useState(null);
    const [isCustomerSelected, setIsCustomerSelected] = useState(false);

    // Handle form submission
    const handleSubmit = async () => {

        const payload = {
            customer_id: selectedCustomerId,
            // amount: parseFloat(fields.Amount.replace(/,/g, '')),
            amount: fields.Amount,
            account_id: fields.AccountName,
            mode: fields.AccountType,
            date: fields.Date || new Date().toISOString().split("T")[0],
        };
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to Refund the Amount?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, update it!',
            cancelButtonText: 'No, cancel!',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
        });
    
        if (result.isConfirmed) {

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}Customer-payment-refund`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });

            if (response.data.status === 1) {
                Swal.fire({
                    title: 'Success',
                    text: 'Refund Amount Successfully!',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                });
                setFields(initialFields);
                onSave();
                setTimeout(() => {
                    navigate('/Account-list');
                }, 1000);
            } else {
                Swal.fire({
                    title: 'Error',
                    text: response.data.message,
                    icon: 'error',
                });
            }
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                title: 'Error',
                text: 'An error occurred during the update',
                icon: 'error',
            });
        } finally {
            setLoading(false);
        }
    } else {
        Swal.fire('Cancelled', 'Refund Amount has been cancelled', 'info');
    }
    };

    const handleSubmitEmployee = () => {
        if (validateFields(fields, setErrors)) {
            setLoading(true);
            handleSubmit();
        }
    };

    useEffect(() => {
        fetchEmployeeList();
    }, []);

    // Fetch employee list for account selection
    const fetchEmployeeList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}Account-list`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            setEmployeeList(response.data.data || []);
            if (response.data.data && response.data.data.length > 0) {
                setselectedAccountId(response.data.data[0].id);
                setFields(prev => ({ ...prev, AccountName: response.data.data[0].id }));
            }
        } catch (error) {
            console.error('Error fetching employee list:', error);
        }
    };

    // Fetch customer list and set default customer
    useEffect(() => {
        const fetchCustumer = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}Custumer-name-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                setCustumerlist(response.data.data || []);
                if (response.data.data && response.data.data.length > 0) {
                    setselectedCustomerId(response.data.data[0].id);
                    setFields(prev => ({ ...prev, CustomerName: response.data.data[0].id }));
                }
            } catch (error) {
                console.error('Error fetching Customer:', error);
            }
        };

        fetchCustumer();
    }, [token]);

    // Fetch total amount based on selected customer ID
    useEffect(() => {
        if (isCustomerSelected && selectedCustomerId) {
            const fetchCustumerRefund = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}Customer-Refund-Amount-Total/${selectedCustomerId}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-access-token': token,
                        },
                    });
                    const amount = response.data.data && response.data.data.length > 0 ? response.data.data[0].amount : '';
                    setTotalAmt(amount);
                    setFields(prev => ({ ...prev, Amount: amount })); // Update the Amount field
                } catch (error) {
                    console.error('Error fetching Customer Refund:', error);
                }
            };

            fetchCustumerRefund();
        }
    }, [selectedCustomerId, token, isCustomerSelected]);

    // Handle customer selection change
    const handleCustomerChange = (e) => {
        const customerId = e.target.value;
        setselectedCustomerId(customerId);
        handleFieldChange('CustomerName')(e);
        setIsCustomerSelected(true);
    };

    return (
        <div id="addVendorModal">
            <Card>
                <Card.Body>
                    <Container fluid>
                        <Row>
                        <Col lg={4} sm={6} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="custom-label">Account Type</Form.Label>
                                    <Form.Select
                                        value={fields.AccountType}
                                        onChange={handleFieldChange('AccountType')}
                                        className={errors.AccountType ? 'is-invalid' : ''}
                                    >
                                        <option value="">Select Account Type</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Online">Online</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={4} sm={6} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="custom-label">Account Name</Form.Label>
                                    <Form.Select
                                        value={fields.AccountName}
                                        onChange={handleFieldChange('AccountName')}
                                        className={errors.AccountName ? 'is-invalid' : ''}
                                    >
                                        <option value="">Select</option>
                                        {employeeList.map((vendor) => (
                                            <option key={vendor.id} value={vendor.id}>
                                                {vendor.account_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={4} sm={6} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="custom-label">Customer Name</Form.Label>
                                    <Form.Select
                                        onChange={handleCustomerChange}
                                        className={errors.CustomerName ? 'is-invalid' : ''}
                                    >
                                        <option value="">Select</option> 
                                        {custumerlist.map((customer) => (
                                            <option key={customer.id} value={customer.id}>
                                                {customer.customer_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                            </Col>

                            {/* Total Amount Field */}
                            <Col lg={4} sm={6} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="custom-label">Total Amount</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Amount"
                                        disabled
                                        value={TotalAmt} // Show the total amount fetched
                                        readOnly
                                        className={errors.Amount ? 'is-invalid' : ''}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg={4} sm={6} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="custom-label">Refund Amount</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Amount"
                                        onChange={e => {
                                            let value = e.target.value.replace(/,/g, ''); // Remove commas for processing
                                            if (!isNaN(value) || value === "") {
                                                value = (value); // Add commas back to the formatted value
                                                handleFieldChange('Amount')({ target: { value } });
                                            }
                                        }}
                                        className={errors.Amount ? 'is-invalid' : ''}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={4} sm={6} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="custom-label">Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="Date"
                                        value={fields.Date || new Date().toISOString().split("T")[0]}
                                        onFocus={(e) => e.target.showPicker()}
                                        onChange={handleFieldChange('Date')}
                                        className={errors.Date ? 'is-invalid' : ''}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
                <Card.Footer>
                    <div className="d-flex justify-content-end">
                        <Button variant="primary" className="btn btn-submit" onClick={handleSubmitEmployee} disabled={loading}>
                            {loading ? 'Submitting...' : 'Refund'}
                        </Button>
                    </div>
                </Card.Footer>
            </Card>
        </div>
    );
};

export default VendorForm;
