import React, { useState, useEffect, useRef } from 'react';
import { Card, Row, Col, Form, Button, Table, Modal } from 'react-bootstrap';
import { MdAddCircle } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { RiDeleteBin6Fill } from "react-icons/ri";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Addproduct from '../commanpage/Addproduct';
import Category_Design from '../commanpage/Category_Design';
import Category_Design_Edit from '../commanpage/Category_Design_Edit';
import { FaEye } from "react-icons/fa";
import OrderProductPdfDownload from './OrderProductPdfDownload'
import AddproductRaw from '../commanpage/AddrowmaterialOrder';
import $ from 'jquery'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { RiDeleteBin6Line } from "react-icons/ri";
export default function Takeorder() {
    const [errors, setErrors] = useState({});
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [productdata, setproduct] = useState([]);
    const [design_category_list, setDesignCategorylist] = useState([]);
    const [viewdata, setViewdata] = useState([]);
    const [accountlist, setAccountList] = useState([]);
    const [venderlist, setVenderlist] = useState([]);
    const [productorderdata, setproductorder] = useState([]);
    const [modalState, setModalState] = useState({ addproduct: false, addraw: false, orderconfirmation: false, adddesignCategory: false, category_design_edit: false });
    const handleCloseEditModal = () => setShowEditModal(false);
    const handleShowEditModal = () => setShowEditModal(true);
    const [showEditModal, setShowEditModal] = useState(false);
    const [searchNameList, setSearchNameList] = useState([]);
    const [filteredNameList, setFilteredNameList] = useState([]);
    const [RawGstAmount, setRawGstAmount] = useState();

    const [currentEditId, setCurrentEditId] = useState(null);
    const getSavedData = (key, defaultValue) => JSON.parse(localStorage.getItem(key)) || defaultValue;

    const [selectedOrderType, setSelectedOrderType] = useState(() => localStorage.getItem("selectedOrderType") || '');
    const [isCGSTChecked, setIsCGSTChecked] = useState(() => localStorage.getItem("isCGSTChecked") || '');

    const [formData, setFormData] = useState(() => getSavedData("formData", {
        order_date: new Date().toISOString().split("T")[0], customer_type: "", customer_name: "", mobile_number: "",
        invoice_number_manual: '', vehicle_number: '', gst_in_no: '', c_gst: '',
        s_gst: '', description: '', email: "", site_name: "", address1: "",
        address2: "", city: "", state: "", pincode: "", manufacture: "Trading",
        amount: "", receiver_name: "", payer_name: "", delivery_date: "", design_price: ''
    }));
    const [rows, setRows] = useState(() => getSavedData('rows', [{
        category_id: null, product_id: null, vendor_id: null, qty: '', price: '', gst: ''
    }]));

    const [rowother, setRowother] = useState(() => getSavedData('rowother', [{
        tr_charge: null, lable_charge: null, name: ''
    }]));

    const [rowdesing, setRowdesing] = useState(() => getSavedData('rowdesing', [{
        design_category_id: null, design_description: null
    }]));

    const handleAddRow = () => { setRows([...rows, { category_id: null, product_id: null, vendor_id: null, qty: '', price: '', gst: '' }]); };
    const handleAddRowOther = () => { setRowother([...rowother, { tr_charge: null, lable_charge: null, name: '' }]); };
    const handleAddRowDesign = () => { setRowdesing([...rowdesing, { design_category_id: null, design_description: null }]); };
    const navigate = useNavigate();
    const categoryOptions = categories.map((item) => ({ value: item.id, label: item.name, }));
    const vendorOptions = venderlist.map((item) => ({ value: item.id, label: item.vendor_name, }));

    useEffect(() => localStorage.setItem('formData', JSON.stringify(formData)), [formData]);
    useEffect(() => localStorage.setItem("selectedOrderType", selectedOrderType), [selectedOrderType]);
    useEffect(() => localStorage.setItem("isCGSTChecked", isCGSTChecked), [isCGSTChecked]);
    useEffect(() => localStorage.setItem('rows', JSON.stringify(rows)), [rows]);
    useEffect(() => localStorage.setItem('rowother', JSON.stringify(rowother)), [rowother]);
    useEffect(() => localStorage.setItem('rowdesing', JSON.stringify(rowdesing)), [rowdesing]);

    const handleOrderTypeChange = (event) => {
        const newOrderType = event.target.value;
        setSelectedOrderType(newOrderType);

        // Reset formData with empty values
        setFormData({
            c_gst: '',
            s_gst: '',
            design_price: '',
            delivery_date: '',
        });
        // Reset rows with one default row
        setRows([{
            category_id: null, product_id: null, vendor_id: null, qty: '', price: '', gst: ''
        }]);

        // Reset rowdesing with one default row
        setRowdesing([{
            design_category_id: null, design_description: null
        }]);

        // Reset rowother with one default row
        setRowother([{
            tr_charge: null, lable_charge: null, name: ''
        }]);
    };
    const handleCheckboxChange = (e) => {
        setIsCGSTChecked(e.target.checked);
    };

    const getProductOptionsForRow = (category_id, manufacture) => {
        return productorderdata.filter(item => item.category_id == category_id && item.manufacture == manufacture)
            .map(item => ({
                value: item.id, label: item.name,
            }));
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'mobile_number') {
            const formattedValue = value.replace(/\D/g, '').slice(0, 10);
            setFormData({ ...formData, [name]: formattedValue });

            if (formattedValue.length !== 10) {
                setErrors((prevErrors) => ({ ...prevErrors, [name]: 'Mobile number must be 10 digits long' }));
            } else {
                setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
            }
        } else if (name === 'vehicle_number' || name === 'gst_in_no') {
            setFormData({
                ...formData,
                [name]: value.toUpperCase(),
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
        if (name === 'customer_name') {
            if (value.trim() !== '') {
                filterSearchName(value);
            } else {
                setSearchNameList([]);
            }
        }
    };
    const handleSelectChange = (name, selectedOption) => {
        // alert(selectedOption);
        if (name === 'category_id' || name === 'account_name' || name === 'account_id' || name === 'account_mode') {
            setFormData({ ...formData, [name]: selectedOption ? selectedOption.value : null });
        } else if (name === 'product_id') {
            const selectedProduct = selectedOption ? { id: selectedOption.value, name: selectedOption.label } : null;
            setFormData({ ...formData, [name]: selectedProduct });
        } else {
            setFormData({ ...formData, [name]: selectedOption });
        }
    };

    const handleRowChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;
        const qty = parseFloat(updatedRows[index].qty) || 0;
        const price = parseFloat(updatedRows[index].price) || 0;
        const gst = parseFloat(updatedRows[index].gst) || 0;
        const total_gst_amount = qty * price * (gst / 100);

        // Update the specific row
        updatedRows[index] = {
            ...updatedRows[index],
            total_gst_amount: total_gst_amount
        };

        // Set the updated rows to state
        setRows(updatedRows);
        setRawGstAmount(total_gst_amount)
    };

    const handleRowChangeOther = (index, field, value) => {
        const updatedRows = [...rowother];
        updatedRows[index][field] = value;
        setRowother(updatedRows);
    };
    const handleRowChangeDesign = (index, field, value) => {
        // alert(value);
        const updatedRows = [...rowdesing];
        // console.warn(updatedRows);
        updatedRows[index][field] = value;
        setRowdesing(updatedRows);
    };
    const validateForm = () => {
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            if (!formData[key] && key !== 'email' && key !== 'site_name' && key !== 'pincode' && key !== 'address2' && key !== 'qty' && key !== 'payer_name' && key !== 'city' && key !== 'state' && key !== 'receiver_name' && key !== 'description' && key !== 'invoice_number_manual' && key !== 'vehicle_number' && key !== 'gst_in_no' && key !== 'c_gst' && key !== 's_gst' && key !== 'delivery_date' && key !== 'design_price') {
                newErrors[key] = 'This field is required';
            }
        });

        // Validate quantity against available stock
        rows.forEach((row, index) => {
            const product = productorderdata.find(item => item.id === row.product_id?.id);
            if (product && parseInt(row.qty) > parseInt(product.pro_qty)) {
                newErrors[`qty_${index}`] = `Quantity exceeds available stock (${product.pro_qty})`;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleSubmit = async (url, formData, rows, successCallback) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to submit this form?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, submit it!',
            cancelButtonText: 'No, cancel!',
        });

        if (result.isConfirmed) {
            setLoading(true);
            try {
                const { product_id, ...requestData } = formData;
                const payload = {
                    ...requestData,
                    delivery_date: formData.delivery_date || new Date().toISOString().split("T")[0],
                    order_date: new Date().toISOString().split("T")[0],
                    // products: productsData,
                };

                const response = await axios.post(url, payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                if (response.data.status === 1) {
                    Swal.fire({
                        title: 'Success',
                        text: response.data.message,
                        icon: 'success',
                        timer: 3000,
                        showConfirmButton: false,
                    });
                    setFormData({
                        order_date:new Date().toISOString().split("T")[0], customer_type: "", customer_name: "", mobile_number: "", invoice_number_manual: '', vehicle_number: '', gst_in_no: '', c_gst: '', s_gst: '', description: '', email: "", site_name: "", address1: "", address2: "", city: "", state: "", pincode: "", amount: "", receiver_name: "", payer_name: "", delivery_date: "",
                    });
                    setRows([]);
                    setRowdesing([]);
                    setRowother([]);
                    localStorage.setItem('orderproduct_id_pdf', response.data.createOrderId);
                    localStorage.removeItem('rows');
                    localStorage.removeItem('rowdesing');
                    localStorage.removeItem('rowother');
                    localStorage.removeItem('formData');
                    localStorage.removeItem('isCGSTChecked');
                    setModalState({ ...modalState, orderconfirmation: true })
                    setTimeout(() => {
                        successCallback && successCallback(response.data.payload);
                    }, 1000);
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: response.data.message,
                        icon: 'error',
                    });

                }
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: 'An error occurred',
                    icon: 'error',
                });
            } finally {
                setLoading(false);
            }
        } else {
            // User canceled the action
            Swal.fire('Cancelled', 'Your submission has been cancelled.', 'info');
        }
    };


    const designPrice = selectedOrderType === 'design' ? parseFloat(formData.design_price) || 0 : 0;
    const total_price = rows.reduce((total, row) => {
        const priceWithGst = row.price * (1 + row.gst / 100);
        return total + (row.qty * priceWithGst);
    }, 0) + designPrice;

    const other_charges_total = rowother.reduce((total, row) => {
        const charge = parseFloat(row.lable_charge) || 0;
        return total + charge;
    }, 0).toFixed(2);

    // Calculate GST amounts based on total price
    const c_gst_percentage = parseFloat(formData.c_gst) || 0;
    const s_gst_percentage = parseFloat(formData.s_gst) || 0;

    const total_c_gst = (parseFloat(total_price) * c_gst_percentage / 100).toFixed(2);
    const total_s_gst = (parseFloat(total_price) * s_gst_percentage / 100).toFixed(2);

    let total_amount = (parseFloat(total_price) + parseFloat(other_charges_total) + parseFloat(total_c_gst) + parseFloat(total_s_gst)).toFixed(2);


    const handleSubmitOrder = () => {
        console.warn(selectedOrderType)
        if (validateForm()) {
            let productsData = [];
            let designData = [];
            let otherData = [];

            // Handle 'product' or 'invoice' order type
            if (selectedOrderType === 'product' || selectedOrderType === 'invoice' || selectedOrderType === 'bill_supply') {
                productsData = rows.map(row => ({
                    category_id: row.category_id,
                    vender_id: row.vendor_id,
                    product_id: row.product_id ? row.product_id.id : null,
                    qty: row.qty,
                    gst: row.gst,
                    price: row.price,
                }));
                otherData = rowother.map(row => ({
                    tr_charge: row.tr_charge,
                    lable_charge: row.lable_charge,
                    name: row.name,
                }));
            }
            // Handle 'design' order type
            if (selectedOrderType === 'design') {
                // This block should execute when 'design' is selected
                designData = rowdesing.map(row => ({
                    design_category_id: row.design_category_id,
                    design_description: row.design_description,
                }));
            }

            // Prepare the request data
            const requestData = {
                ...formData,
                total_price,
                other_charges_total: 0,
                total_c_gst,
                total_s_gst,
                total_amount,
                products: productsData,
                designs: designData,
                otherdata: otherData,
                order_type: selectedOrderType,
            };
            // Submit the order
            handleSubmit(
                `${process.env.REACT_APP_API_URL}Order-store`,
                requestData,
                () => {
                    // Success callback
                }
            );
        } else {
            // Validation failed
            console.log("Validation failed");
        }
    };

    const filterSearchName = async (searchTerm) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}Order-search-list`, { customer_name: searchTerm }, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });

            if (response.data.status === 1) {
                setSearchNameList(response.data.data || []);
            } else {
                setSearchNameList([]);
            }
        } catch (error) {
            console.error('Error fetching search results:', error);
            setSearchNameList([]);
        }
    };
    const fetchCustomerDetails = async (id) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}Order-search-customer-list/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });

            const customerData = response.data.data[0];
            setFormData({
                order_date: customerData.order_date,
                customer_name: customerData.customer_name,
                customer_type: customerData.customer_type, // Assuming customer_type is fetched
                mobile_number: customerData.mobile_number,
                invoice_number_manual: customerData.invoice_number_manual,
                vehicle_number: customerData.vehicle_number,
                gst_in_no: customerData.gst_in_no,
                site_name: customerData.site_name,
                pincode: customerData.pincode,
                email: customerData.email,
                city: customerData.city,
                state: customerData.state,
                address1: customerData.address1,
                address2: customerData.address2,
                account_name: customerData.account_name,
                account_mode: customerData.account_mode,
                payer_name: customerData.payer_name,
                receiver_name: customerData.receiver_name,
            });
            setFilteredNameList([]);
        } catch (error) {
            console.error('Error fetching customer details:', error);
        }
    };


    const fetchData = async (url, token, setData, errorMessage) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}${url}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            setData(response.data.data || []);
        } catch (error) {
            console.error(errorMessage, error);
        }
    };

    useEffect(() => {
        if (!modalState.addproduct && rows) {
            fetchData('category-list', token, setCategories, 'Error fetching categories:');
        }
    }, [token, modalState.addproduct]);

    useEffect(() => {
        fetchData('Vender-list', token, setVenderlist, 'Error fetching vendors:');
    }, [token]);

    useEffect(() => {
        if (!modalState.addproduct && rows) {
            fetchData('product-list', token, setproduct, 'Error fetching products:');
        }
    }, [token, modalState.addproduct, rows]);

    useEffect(() => {
        if (!modalState.addraw || !modalState.addproduct) {
            fetchData('product-vieworder', token, setproductorder, 'Error fetching product orders:');
        }
    }, [token, modalState.addraw, modalState.addproduct]);

    useEffect(() => {
        fetchAccountList();
    }, []);

    useEffect(() => {
        if (!modalState.adddesignCategory || !modalState.category_design_edit) {
            fetchData('Design-category-list', token, setDesignCategorylist, 'Error fetching design categories:');
        }
    }, [token, modalState.adddesignCategory, modalState.category_design_edit]);

    const fetchAccountList = async () => {
        await fetchData('Account-list', token, setAccountList, 'Error fetching account list:');
    };

    const ProductView = async (id) => {
        setCurrentEditId(id);
        handleShowEditModal();
        await fetchData(`product-view/${id}`, token, setViewdata, 'There was a problem with the fetch operation:');
    };
    const handleDeleteProduct = (productId) => {
        const updatedProducts = productorderdata.filter(product => product.id !== productId);
        setproductorder(updatedProducts);

    };
    const accountModeOptions = [
        { value: 'Cash', label: 'Cash' },
        { value: 'Online', label: 'Online' },
        // { value: 'Either or Survivor Mode', label: 'Either or Survivor Mode' },
    ];

    const handleDeleteRow = (index) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
    };
    const handleDeleteRowother = (index) => {
        const updatedRows = rowother.filter((_, i) => i !== index);
        setRowother(updatedRows);
    };
    const handleDeleteRowdesign = (index) => {
        const updatedRows = rowdesing.filter((_, i) => i !== index);
        setRowdesing(updatedRows);
    };
    const handleSearchItemClick = async (selectedName, customerId) => {
        setFormData({ ...formData, customer_name: selectedName });
        setSearchNameList([]); // Clear search results after selecting an item
        fetchCustomerDetails(customerId);
    };

    const handleClear = () => {
        setFormData({
            order_date: "", customer_type: "", customer_name: "", mobile_number: "",
            invoice_number_manual: '', vehicle_number: '', gst_in_no: '', c_gst: '',
            s_gst: '', description: '', email: "", site_name: "", address1: "",
            address2: "", city: "", state: "", pincode: "", manufacture: "Trading",
            amount: "", receiver_name: "", payer_name: "", delivery_date: "", design_price: ''
        });

        setRows([{
            category_id: null, product_id: null, vendor_id: null, qty: '', price: '', gst: ''
        }]);

        setRowother([{
            tr_charge: null, lable_charge: null, name: ''
        }]);

        setRowdesing([{
            design_category_id: null, design_description: null
        }]);

        // // Optionally, remove any relevant data from localStorage
        localStorage.removeItem('formData');
        localStorage.removeItem('rows');
        localStorage.removeItem('rowother');
        localStorage.removeItem('rowdesing');
    };

    return (
        <section className="takeorder_section">

            <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <Card.Title>Create Order</Card.Title>
                    <Button variant="primary" onClick={handleClear}>Clear</Button>{' '}
                </Card.Header>
                <Card.Body>
                    <div className="takeorder_heading">
                        <h4 className="mb-0">Customer Information</h4>
                    </div>
                    <Row>
                        <Col md={4} lg={4} xs={12}>
                            <Form.Group>
                                <Form.Label>Select Order Type</Form.Label>
                                <Form.Select
                                    value={selectedOrderType}
                                    onChange={handleOrderTypeChange}
                                >
                                    <option value="">Select Order</option>
                                    <option value="invoice">Invoice</option>
                                    <option value="product">Order Confirmation</option>
                                    <option value="bill_supply">Bill Supply</option>
                                    <option value="design">Design</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        {[
                            { label: "Date", name: "order_date", type: "date" },
                            { label: "Customer Type", name: "customer_type", type: "select", options: [{ value: "Retail", label: "Retail" }, { value: "Site", label: "Site" }, { value: "Design", label: "Design" }] },
                            { label: "Customer Name", name: "customer_name", type: "text" },
                            { label: "Mobile Number", name: "mobile_number", type: "text" },
                            { label: "Site Name", name: "site_name", type: "text" },
                            { label: "Pin Code", name: "pincode", type: "text" },
                            { label: "Email", name: "email", type: "email" },
                            { label: "City", name: "city", type: "text" },
                            { label: "State", name: "state", type: "text" },
                            { label: "Address One", name: "address1", type: "text" },
                            { label: "Address Two", name: "address2", type: "text" },
                        ].map(({ label, name, type, options }, idx) => (
                            <Col md={4} lg={4} key={idx}>
                                <Form.Group controlId={name} className="position-relative">
                                    <Form.Label>{label}</Form.Label>
                                    {type === "select" ? (
                                        <Form.Select
                                            name={name}
                                            value={formData[name]}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors[name]}
                                        >
                                            <option value="">Select {label}</option>
                                            {options.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    ) : (
                                        <Form.Control
                                            type={type}
                                            name={name}
                                            placeholder={`Enter ${label}`}
                                            value={name === "order_date" && !formData[name] ? new Date().toISOString().split("T")[0] : formData[name]}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors[name]}
                                            onFocus={name === "order_date" ? (e) => e.target.showPicker() : null}
                                        />
                                    )}
                                    {name === "customer_name" && searchNameList.length > 0 && (
                                        <div className="search-results">
                                            {searchNameList.map((item, index) => (
                                                <div
                                                    key={index}
                                                    className="search-item"
                                                    onClick={() =>
                                                        handleSearchItemClick(item.customer_name, item.id)
                                                    }
                                                >
                                                    {item.customer_name}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </Form.Group>
                            </Col>
                        ))}
                        <Col md={6} xs={12} lg={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="description"
                                    placeholder="Description"
                                    value={formData.description}
                                    onChange={handleInputChange}
                                // isInvalid={!!errors.amount}
                                />
                            </Form.Group>
                        </Col>

                    </Row>
                    {(selectedOrderType === 'invoice') && (
                        <Row>
                            <Col md={2} xs={2} lg={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Invoice Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="invoice_number_manual"
                                        placeholder="Invoice Number"
                                        value={formData.invoice_number_manual}
                                        onChange={handleInputChange}
                                    // isInvalid={!!errors.amount}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={2} xs={2} lg={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Vehicle Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="vehicle_number"
                                        placeholder="Vehicle Number"
                                        value={formData.vehicle_number}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={2} xs={2} lg={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>GSTIN No</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="gst_in_no"
                                        placeholder="GSTIN No"
                                        value={formData.gst_in_no}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                </Card.Body>
            </Card>
            {selectedOrderType === 'invoice' && (
                <>
                    <Card>
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <Card.Title>Select Product</Card.Title>
                            <Button onClick={() => setModalState({ ...modalState, addproduct: true })} className="btn-added addproduct">
                                <MdAddCircle /> Create Product
                            </Button>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={12} xs={12} lg={12}>
                                    <label><strong>Manufacture Type</strong></label>
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex gap-2 align-items-center">
                                            <Form.Check
                                                type="radio"
                                                label="Trading"
                                                name="manufacture"
                                                id="product_type_trading"
                                                value="Trading"
                                                onChange={handleInputChange}
                                                checked={formData.manufacture === 'Trading'}
                                            />
                                            <Form.Check
                                                type="radio"
                                                label="Manufacture"
                                                name="manufacture"
                                                id="product_type_manufacture"
                                                value="Manufacture"
                                                onChange={handleInputChange}
                                                checked={formData.manufacture === 'Manufacture'}
                                            />
                                        </div>
                                        <Button onClick={handleAddRow} className="btn-added addproduct">
                                            <MdAddCircle /> Add More
                                        </Button>
                                    </div>
                                </Col>

                            </Row>
                            {rows.map((row, index) => (
                                <Row key={index} className="mt-3">
                                    <Col xs={12} md={4} lg={2}>
                                        <Form.Group className="mb-3" controlId={`category_id_${index}`}>
                                            <Form.Label>Product Category</Form.Label>
                                            <Select

                                                value={categoryOptions.find((option) => option.value === row.category_id)}
                                                options={categoryOptions}
                                                isSearchable={true}
                                                placeholder="Select"
                                                onChange={(selected) => handleRowChange(index, 'category_id', selected ? selected.value : null)}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} md={4} lg={3}>
                                        <Form.Group className="mb-3" controlId={`product_name_${index}`}>
                                            <div className="d-flex justify-content-between">
                                                <Form.Label>Product Name</Form.Label>
                                            </div>
                                            <Select
                                                isMulti={false}
                                                className="basic-multi-select"
                                                options={getProductOptionsForRow(row.category_id, formData.manufacture)}
                                                value={row.product_id ? { value: row.product_id.id, label: row.product_id.name } : null}
                                                isSearchable={true}
                                                placeholder="Select"
                                                onChange={(selected) => handleRowChange(index, 'product_id', selected ? { id: selected.value, name: selected.label } : null)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} lg={2} key={index}>
                                        <Form.Group className="mb-3" controlId={`vendor_id_${index}`}>
                                            <Form.Label>Vendor Name</Form.Label>
                                            <Select
                                                value={vendorOptions.find((option) => option.value === row.vendor_id)}
                                                options={vendorOptions}
                                                isSearchable={true}
                                                placeholder="Select"
                                                onChange={(selected) => handleRowChange(index, 'vendor_id', selected ? selected.value : null)}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} md={4} lg={2}>
                                        <Form.Group className="mb-3" controlId={`qty_${index}`}>
                                            <Form.Label>QTY</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="qty"
                                                placeholder="QTY"
                                                value={row.qty}
                                                onChange={(e) => handleRowChange(index, 'qty', e.target.value)}
                                                isInvalid={!!errors[`qty_${index}`]}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors[`qty_${index}`]}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col xs={2} md={4} lg={2}>
                                        <Form.Group className="mb-3" controlId={`price_${index}`}>
                                            <Form.Label>Unit Price</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="price"
                                                placeholder="Enter Price"
                                                value={row.price}
                                                onChange={(e) => handleRowChange(index, 'price', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={1} xs={12} lg={1} className='d-flex align-items-center'>
                                        <button
                                            className='btn-added'
                                            onClick={() => handleDeleteRow(index)}
                                        >
                                            <RiDeleteBin6Line />
                                        </button>
                                    </Col>
                                </Row>
                            ))}
                        </Card.Body>
                    </Card>
                    {rows.some(row => row.product_id) ? (
                        <Card className='mt-2'>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <Card.Title>Product Details</Card.Title>
                                <Button onClick={() => setModalState({ ...modalState, addraw: true })} className="btn-added addproduct">
                                    <MdAddCircle /> Add Raw
                                </Button>
                            </Card.Header>
                            <Card.Body>
                                <div className="table-responsive">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr className="thead-dark">
                                                <th>Sr No</th>
                                                <th>Product Name</th>
                                                <th>Unit</th>
                                                <th>Qty</th>
                                                <th>Sale Price</th>
                                                <th>Gst In</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {productorderdata && productorderdata.length > 0 ? (
                                                productorderdata
                                                    .filter(item => rows.some(row => row.product_id && row.product_id.id === item.id))
                                                    .map((item, index) => (
                                                        <tr key={item.id}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.pro_unit}</td>
                                                            <td>{item.pro_qty}</td>
                                                            {item.manufacture == 'Manufacture' ? (
                                                                <td>{item.sale_man_amount}</td>
                                                            ) : <td>{item.sale_price}</td>}
                                                            {/* <td>{item.sale_price}</td> */}
                                                            <td>{item.gst}</td>
                                                            <td className="edit-delete-action justify-content-start d-flex gap-2">

                                                                <Link to="#" style={{ "--i": "#233882" }} className="p-2" onClick={() => ProductView(item.id)}><FaEye /></Link>

                                                            </td>
                                                        </tr>
                                                    ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="7" className='text-center'>
                                                        <span>Select Product Name</span>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                        </Card>
                    ) : null}


                    <Card>
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <Card.Title>Other Charges</Card.Title>
                            <Button onClick={handleAddRowOther} className="btn-added addproduct">
                                <MdAddCircle /> Add More
                            </Button>
                        </Card.Header>
                        <Card.Body>
                            {rowother.map((row, index) => (
                                <Row key={index} className="mt-3">
                                    <Col md={2} xs={12} lg={3}>
                                        <Form.Group className="mb-3" controlId={`price_${index}`}>
                                            {/* <Form.Label></Form.Label> */}
                                            <Form.Control
                                                type="text"
                                                name="tr_charge"
                                                placeholder="Name"
                                                value={row.tr_charge}
                                                onChange={(e) => handleRowChangeOther(index, 'tr_charge', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>


                                    <Col md={2} xs={12} lg={4}>
                                        <Form.Group className="mb-3" controlId={`price_${index}`}>
                                            {/* <Form.Label></Form.Label> */}
                                            <Form.Control
                                                type="text"
                                                name="lable_charge"
                                                placeholder="Value"
                                                value={row.lable_charge}
                                                onChange={(e) => handleRowChangeOther(index, 'lable_charge', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={1} xs={12} lg={1} className='d-flex align-items-center'>
                                        <button
                                            className='btn-added'
                                            onClick={() => handleDeleteRowother(index)}
                                        >
                                            <RiDeleteBin6Line />
                                        </button>
                                    </Col>

                                </Row>
                            ))}
                        </Card.Body>
                    </Card>
                </>
            )}
            {/* {selectedOrderType === 'product' || selectedOrderType === 'bill_supply' && ( */}
            {(selectedOrderType === 'product' || selectedOrderType === 'bill_supply') && (

                <>
                    <Card>
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <Card.Title>Select Product</Card.Title>
                            <Button onClick={() => setModalState({ ...modalState, addproduct: true })} className="btn-added addproduct">
                                <MdAddCircle /> Create Product
                            </Button>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={12} xs={12} lg={12}>
                                    <label><strong>Manufacture Type</strong></label>
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex gap-2 align-items-center">
                                            <Form.Check
                                                type="radio"
                                                label="Trading"
                                                name="manufacture"
                                                id="product_type_trading"
                                                value="Trading"
                                                onChange={handleInputChange}
                                                checked={formData.manufacture === 'Trading'}
                                            />
                                            <Form.Check
                                                type="radio"
                                                label="Manufacture"
                                                name="manufacture"
                                                id="product_type_manufacture"
                                                value="Manufacture"
                                                onChange={handleInputChange}
                                                checked={formData.manufacture === 'Manufacture'}
                                            />
                                        </div>
                                        <Button onClick={handleAddRow} className="btn-added addproduct">
                                            <MdAddCircle /> Add More
                                        </Button>
                                    </div>
                                </Col>

                            </Row>
                            {rows.map((row, index) => (
                                <Row key={index} className="mt-3">
                                    <Col xs={12} md={4} lg={2}>
                                        <Form.Group className="mb-3" controlId={`category_id_${index}`}>
                                            <Form.Label>Product Category</Form.Label>
                                            <Select

                                                value={categoryOptions.find((option) => option.value === row.category_id)}
                                                options={categoryOptions}
                                                isSearchable={true}
                                                placeholder="Select"
                                                onChange={(selected) => handleRowChange(index, 'category_id', selected ? selected.value : null)}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} md={4} lg={3}>
                                        <Form.Group className="mb-3" controlId={`product_name_${index}`}>
                                            <div className="d-flex justify-content-between">
                                                <Form.Label>Product Name</Form.Label>
                                            </div>
                                            <Select
                                                isMulti={false}
                                                className="basic-multi-select"
                                                options={getProductOptionsForRow(row.category_id, formData.manufacture)}
                                                value={row.product_id ? { value: row.product_id.id, label: row.product_id.name } : null}
                                                isSearchable={true}
                                                placeholder="Select"
                                                onChange={(selected) => handleRowChange(index, 'product_id', selected ? { id: selected.value, name: selected.label } : null)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} lg={2} key={index}>
                                        <Form.Group className="mb-3" controlId={`vendor_id_${index}`}>
                                            <Form.Label>Vendor Name</Form.Label>
                                            <Select
                                                value={vendorOptions.find((option) => option.value === row.vendor_id)}
                                                options={vendorOptions}
                                                isSearchable={true}
                                                placeholder="Select"
                                                onChange={(selected) => handleRowChange(index, 'vendor_id', selected ? selected.value : null)}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} md={4} lg={2}>
                                        <Form.Group className="mb-3" controlId={`qty_${index}`}>
                                            <Form.Label>QTY</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="qty"
                                                placeholder="QTY"
                                                value={row.qty}
                                                onChange={(e) => handleRowChange(index, 'qty', e.target.value)}
                                                isInvalid={!!errors[`qty_${index}`]}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors[`qty_${index}`]}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col xs={2} md={4} lg={2}>
                                        <Form.Group className="mb-3" controlId={`price_${index}`}>
                                            <Form.Label>Unit Price</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="price"
                                                placeholder="Enter Price"
                                                value={row.price}
                                                onChange={(e) => handleRowChange(index, 'price', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={1} xs={12} lg={1} className='d-flex align-items-center'>
                                        <button
                                            className='btn-added'
                                            onClick={() => handleDeleteRow(index)}
                                        >
                                            <RiDeleteBin6Line />
                                        </button>
                                    </Col>
                                </Row>
                            ))}
                        </Card.Body>
                    </Card>
                    {rows.some(row => row.product_id) ? (
                        <Card className='mt-2'>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <Card.Title>Product Details</Card.Title>
                                <Button onClick={() => setModalState({ ...modalState, addraw: true })} className="btn-added addproduct">
                                    <MdAddCircle /> Add Raw
                                </Button>
                            </Card.Header>
                            <Card.Body>
                                <div className="table-responsive">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr className="thead-dark">
                                                <th>Sr No</th>
                                                <th>Product Name</th>
                                                <th>Unit</th>
                                                <th>Qty</th>
                                                <th>Sale Price</th>
                                                <th>Gst In</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {productorderdata && productorderdata.length > 0 ? (
                                                productorderdata
                                                    .filter(item => rows.some(row => row.product_id && row.product_id.id === item.id))
                                                    .map((item, index) => (
                                                        <tr key={item.id}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.pro_unit}</td>
                                                            <td>{item.pro_qty}</td>
                                                            {item.manufacture == 'Manufacture' ? (
                                                                <td>{item.sale_price}</td>
                                                            ) : <td>{item.sale_man_amount}</td>}
                                                            <td>{item.gst}</td>
                                                            <td className="edit-delete-action justify-content-start d-flex gap-2">

                                                                <Link to="#" style={{ "--i": "#233882" }} className="p-2" onClick={() => ProductView(item.id)}><FaEye /></Link>

                                                            </td>
                                                        </tr>
                                                    ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="7" className='text-center'>
                                                        <span>Select Product Name</span>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                        </Card>
                    ) : null}


                    <Card>
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <Card.Title>Other Charges</Card.Title>
                            <Button onClick={handleAddRowOther} className="btn-added addproduct">
                                <MdAddCircle /> Add More
                            </Button>
                        </Card.Header>
                        <Card.Body>
                            {rowother.map((row, index) => (
                                <Row key={index} className="mt-3">
                                    <Col md={2} xs={12} lg={3}>
                                        <Form.Group className="mb-3" controlId={`price_${index}`}>
                                            {/* <Form.Label></Form.Label> */}
                                            <Form.Control
                                                type="text"
                                                name="tr_charge"
                                                placeholder="Name"
                                                value={row.tr_charge}
                                                onChange={(e) => handleRowChangeOther(index, 'tr_charge', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>


                                    <Col md={2} xs={12} lg={4}>
                                        <Form.Group className="mb-3" controlId={`price_${index}`}>
                                            {/* <Form.Label></Form.Label> */}
                                            <Form.Control
                                                type="text"
                                                name="lable_charge"
                                                placeholder="Value"
                                                value={row.lable_charge}
                                                onChange={(e) => handleRowChangeOther(index, 'lable_charge', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={1} xs={12} lg={1} className='d-flex align-items-center'>
                                        <button
                                            className='btn-added'
                                            onClick={() => handleDeleteRowother(index)}
                                        >
                                            <RiDeleteBin6Line />
                                        </button>
                                    </Col>

                                </Row>
                            ))}
                        </Card.Body>
                    </Card>
                </>
            )}
            {selectedOrderType === 'design' && (
                <>
                    <Card>
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <Card.Title>Add Design</Card.Title>
                            <Button onClick={handleAddRowDesign} className="btn-added addproduct">
                                <MdAddCircle /> Add More
                            </Button>
                        </Card.Header>
                        <Card.Body>
                            {rowdesing.map((row, index) => (
                                <Row key={index} className="mt-3">
                                    <Col md={3} sm={3}>
                                        <Form.Group >
                                            <div className="d-flex justify-content-between">
                                                <Form.Label >Category Design</Form.Label>
                                                <Button
                                                    className='btn-info btn-sm ml-1 mb-3'
                                                    onClick={() => setModalState({ ...modalState, adddesignCategory: true })}
                                                    variant="primary"
                                                >
                                                    Add
                                                </Button>
                                                <Button
                                                    className='btn-red btn-sm mb-3'
                                                    onClick={() => setModalState({ ...modalState, category_design_edit: true })}
                                                    variant="primary"
                                                >
                                                    Edit
                                                </Button>
                                            </div>
                                            <Form.Select

                                                value={row.design_category_id}
                                                onChange={(e) => handleRowChangeDesign(index, 'design_category_id', e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                {design_category_list.map((vendor) => (
                                                    <option key={vendor.id} value={vendor.id}>
                                                        {vendor.design_category_name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={2} xs={12} lg={8}>
                                        <Form.Label>Description</Form.Label>
                                        <ReactQuill
                                            value={row.design_description}
                                            onChange={(content) => handleRowChangeDesign(index, 'design_description', content)}
                                            theme="snow"
                                            modules={{
                                                toolbar: [
                                                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                    [{ size: [] }],
                                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'], ['clean'],
                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                    { 'indent': '-1' }, { 'indent': '+1' }],
                                                ],
                                            }}
                                        />

                                    </Col>
                                    <Col md={1} xs={12} lg={1} className='d-flex align-items-center'>
                                        <button
                                            className='btn-added'
                                            onClick={() => handleDeleteRowdesign(index)}
                                        >
                                            <RiDeleteBin6Line />
                                        </button>
                                    </Col>
                                </Row>
                            ))}
                        </Card.Body>
                    </Card>
                </>
            )}
            <Card>
                {selectedOrderType === 'design' && (
                    <Col md={12} xs={12} lg={12}>
                        <Form.Group className="pe-5">
                            <div className='d-flex m-3'>
                                <Form.Label>GST</Form.Label>
                                <Form.Check
                                    type="checkbox"
                                    // label="CGST %"
                                    name="c_gst"
                                    checked={isCGSTChecked}
                                    onChange={handleCheckboxChange}
                                    className='ms-1'
                                />
                            </div>
                        </Form.Group>
                    </Col>
                )}
                <Card.Body>

                    <Row>
                        {selectedOrderType === 'invoice' || isCGSTChecked ? (
                            <>
                                <Col md={2} xs={2} lg={2}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>CGST %</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="c_gst"
                                            placeholder="CGST %"
                                            value={formData.c_gst}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={2} xs={2} lg={2}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>SGST %</Form.Label>
                                        <Form.Control
                                            type="number" // should be 'number' if it’s a percentage input
                                            name="s_gst"
                                            placeholder="SGST %"
                                            value={formData.s_gst}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </>
                        ) : null}

                        {selectedOrderType === 'design' ? (
                            <Col md={4} xs={4} lg={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Design Price</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="design_price"
                                        placeholder="Design Price"
                                        value={formData.design_price}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                        ) : null}
                    </Row>
                    <Row>
                        <>

                            <Col md={2} lg={2} col={12}>
                                <Form.Label>Total price</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="total_price"
                                    placeholder="Enter Total Price"
                                    disabled
                                    value={new Intl.NumberFormat().format(total_price.toFixed(2))}
                                    readOnly
                                />
                            </Col>
                            {selectedOrderType === 'invoice' || isCGSTChecked ? (
                                <>
                                    <Col md={2} xs={2} lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Cgst </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="total_c_gst"
                                                placeholder="CGST "
                                                disabled
                                                value={total_c_gst}
                                                readOnly
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>


                                    <Col md={2} xs={2} lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Sgst </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="total_s_gst"
                                                placeholder="SGST "
                                                disabled
                                                value={total_s_gst}
                                                readOnly
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </>
                            ) : null}
                            <Col md={2} lg={2} col={12}>
                                <Form.Label>Total Amount</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="total_price"
                                    placeholder="Enter Total Price"
                                    disabled
                                    value={new Intl.NumberFormat().format(total_amount)}
                                    readOnly
                                />
                            </Col>

                            <Col md={3} lg={3} col={12}>
                                <Form.Group controlId="delivery_date">
                                    <Form.Label>Delivery Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="delivery_date"
                                        placeholder="Delivery Date"
                                        value={formData.delivery_date || new Date().toISOString().split("T")[0]} // Default to today's date
                                        onChange={handleInputChange}
                                        onFocus={(e) => e.target.showPicker()} // Opens calendar on focus
                                        isInvalid={!!errors.delivery_date}
                                    />
                                </Form.Group>
                            </Col>

                        </>
                    </Row>
                </Card.Body>
            </Card>

            <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <Card.Title>Account</Card.Title>

                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={4} lg={4} col={12}>
                            <Form.Group controlId="amount">
                                <Form.Label>Enter Receive Amount</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="amount"
                                    placeholder="Enter Receive Amount"
                                    value={formData.amount}
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.amount}
                                />

                            </Form.Group>
                        </Col>
                        <Col md={4} lg={4} xs={12}>
                            <Form.Group controlId="account_name">
                                <Form.Label>Select Account</Form.Label>
                                <Select
                                    value={accountlist && accountlist.find(option => option.value === formData.account_id)}
                                    onChange={(selected) => handleSelectChange('account_id', selected)}
                                    options={accountlist && accountlist.map(employee => ({
                                        value: employee.id,
                                        label: employee.account_name
                                    }))}
                                />
                            </Form.Group>
                        </Col>

                        <Col md={4} lg={4} col={12}>
                            <Form.Group controlId="account_mode">
                                <Form.Label>Select Mode</Form.Label>
                                <Select
                                    value={accountModeOptions.find(option => option.value === formData.account_mode)}
                                    onChange={(selected) => handleSelectChange('account_mode', selected)}
                                    options={accountModeOptions}

                                />
                            </Form.Group>
                        </Col>

                        <Col md={4} lg={4} col={12}>
                            <Form.Group controlId="receiver_name">
                                <Form.Label>Receiver Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="receiver_name"
                                    placeholder="Receiver Name"
                                    value={formData.receiver_name}
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.receiver_name}
                                />

                            </Form.Group>
                        </Col>
                        <Col md={4} lg={4} col={12}>
                            <Form.Group controlId="payer_name">
                                <Form.Label>Payer Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="payer_name"
                                    placeholder="Payer Name"
                                    value={formData.payer_name}
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.payer_name}
                                />

                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <div className="d-flex justify-content-end">
                <div className="d-flex gap-1">
                    <Button className="btn takeorder" disabled={loading} onClick={handleSubmitOrder} >
                        {loading ? 'Saving...' : 'Create Order'}
                    </Button>
                </div>
            </div>
            <Modal
                size="xl"
                show={modalState.addproduct}
                onHide={() => setModalState({ ...modalState, addproduct: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                data-backdrop="static" data-keyboard="false"
            >
                <Modal.Header closeButton>
                    <h3 className="card-title mb-0">Add Product</h3>
                </Modal.Header>

                <Modal.Body closeButton>
                    <Addproduct onSave={() => setModalState({ ...modalState, addproduct: false })} />
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={modalState.addraw}
                onHide={() => setModalState({ ...modalState, addraw: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                data-backdrop="static" data-keyboard="false"

            >

                <Modal.Body closeButton>
                    <AddproductRaw onSave={() => setModalState({ ...modalState, addraw: false })} />
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={modalState.orderconfirmation}
                onHide={() => setModalState({ ...modalState, orderconfirmation: false })}
                data-backdrop="static" data-keyboard="false"
            >
                <Modal.Header closeButton>
                    <h3 className="card-title mb-0">Order Pdf</h3>
                </Modal.Header>

                <Modal.Body closeButton>
                    <OrderProductPdfDownload onSave={() => setModalState({ ...modalState, orderconfirmation: false })} />
                </Modal.Body>
            </Modal>
            <Modal show={showEditModal} size="lg" onHide={handleCloseEditModal} data-backdrop="static" data-keyboard="false">
                <Modal.Header closeButton>
                    <Modal.Title><h4>View Details</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive">
                        {/* <h6 className="fw-bold">Product details</h6> */}
                        <table className="table">
                            <tbody>
                                {viewdata.map((item) => (
                                    <React.Fragment key={item.id}>
                                        <tr>
                                            <td colSpan="6">
                                                <h6 className="fw-bold">Row Materials</h6>
                                                <table className="table">
                                                    <thead className='thead-primary'>
                                                        <tr>
                                                            {/* <th>Id</th> */}
                                                            <th>Name</th>
                                                            <th>Qty</th>
                                                            <th>Unit Cost Price</th>
                                                            <th>Unit Sale Price</th>
                                                            <th>Cost Price</th>
                                                            <th>Sale Price</th>
                                                            <th>Vendor Name</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {item.row_materials.map(material => (
                                                            <tr key={material.id}>
                                                                {/* <td>{material.id}</td> */}
                                                                <td>{material.raw_name}</td>
                                                                {/* <td>demo</td> */}
                                                                <td>{material.qty}</td>
                                                                <td>{material.u_cost_prize}</td>
                                                                <td>{material.unit_sale_price}</td>
                                                                <td>{material.Costprice}</td>
                                                                <td>{material.Saleprice}</td>
                                                                <td>{material.vender_Name}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="6">
                                                <h6 className="fw-bold">Attributes</h6>
                                                <table className="table">
                                                    <thead className='thead-info'>
                                                        <tr>
                                                            <th>Id</th>
                                                            <th>Name</th>
                                                            <th>Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {item.attributes.map(attr => (
                                                            <tr key={attr.id}>
                                                                <td>{attr.id}</td>
                                                                <td>{attr.name}</td>
                                                                <td>{attr.value}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </Modal.Body>

            </Modal>
            <Modal
                show={modalState.adddesignCategory}
                onHide={() => setModalState({ ...modalState, adddesignCategory: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h3 className="card-title mb-0">Add Category Design</h3>
                </Modal.Header>
                <Modal.Body>
                    <Category_Design onSave={() => setModalState({ ...modalState, category_expenditure: false })} />
                </Modal.Body>
            </Modal>
            <Modal
                show={modalState.category_design_edit}
                onHide={() => setModalState({ ...modalState, category_design_edit: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h3 className="card-title mb-0">Edit Category Design</h3>
                </Modal.Header>
                <Modal.Body>
                    <Category_Design_Edit onSave={() => setModalState({ ...modalState, category_design_edit: false })} />
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </section >

    );
}
